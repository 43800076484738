import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import MikeColors from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import ProjectsTree from '../ProjectsTree'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme =>
  createStyles({
    closeButton: {
      marginLeft: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1)
    },
    dialog: {
      minWidth: 560,
      maxHeight: 590
      // padding: theme.spacing(3)
    },
    dialogContent: {
      maxHeight: 542,
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: MikeColors.DARKGREY_DEFAULT + ' ' + MikeColors.WHITE,
      '&::-webkit-scrollbar': {
        width: 24,
        borderRadius: '4px 4px'
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '4px 4px',
        backgroundColor: MikeColors.MEDIUMGREY_LIGHT,
        borderRight: '8px solid ' + MikeColors.WHITE,
        borderLeft: '12px solid ' + MikeColors.WHITE
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px 4px',
        backgroundColor: MikeColors.DARKGREY_DEFAULT,
        borderRight: '8px solid ' + MikeColors.WHITE,
        borderLeft: '12px solid ' + MikeColors.WHITE,
        '&:hover': {
          borderRight: '8px solid ' + MikeColors.WHITE,
          borderLeft: '12px solid ' + MikeColors.WHITE
        }
      }
    }
  })
)

function ProjectsTreeDialog(props) {
  const intl = useIntl()
  const classes = useStyles()
  const {
    onExpand,
    open,
    onClose,
    onMove,
    projectTree,
    selectedIds,
    disabledIds,
    loadingInitialTree,
    parentId
  } = props
  const [selectedProjectId, setSelectedProjectId] = React.useState(null)

  const handleClose = () => {
    onClose()
  }

  const handleHandleOnExpand = nodeId => {
    onExpand(nodeId)
  }

  const handleHandleOnSelect = nodeId => {
    setSelectedProjectId(nodeId)
  }

  const handleMove = () => {
    onMove(selectedProjectId)
  }

  const tooltip =
    selectedIds && selectedIds.length > 1
      ? intl.formatMessage({
          id: 'projectTree.tooltip.plural'
        })
      : intl.formatMessage({
          id: 'projectTree.tooltip.singular'
        })
  const selectedTooltip = intl.formatMessage({
    id: 'projectTree.tooltip.selected'
  })
  const parentTooltip = intl.formatMessage({ id: 'projectTree.tooltip.parent' })
  const title =
    selectedIds && selectedIds.length > 1
      ? intl.formatMessage({ id: 'projectTreeDialog.heading.title.plural' })
      : intl.formatMessage({
          id: 'projectTreeDialog.heading.title.singular'
        })

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="mike-dataadmin-move-dialog-title"
      open={open}
      className={classes.dialog}
    >
      <DialogTitle id="mike-dataadmin-move-dialog-title">
        {title}
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loadingInitialTree ? (
          <CircularProgress />
        ) : (
          <ProjectsTree
            onExpand={handleHandleOnExpand}
            onSelect={handleHandleOnSelect}
            projectTree={projectTree}
            selectedIds={selectedIds}
            disabledIds={disabledIds}
            tooltip={tooltip}
            selectedItemTooltip={selectedTooltip}
            parentIdTooltip={parentTooltip}
            parentId={parentId}
          />
        )}
      </DialogContent>
      <DialogActions>
        <MikeButton
          onClick={handleClose}
          buttontype="text"
          className={classes.button}
        >
          Cancel
        </MikeButton>
        <MikeButton
          disabled={selectedProjectId === null}
          onClick={handleMove}
          buttontype="primary"
          className={classes.button}
        >
          {intl.formatMessage({ id: 'projectTreeDialog.moveButton.label' })}
        </MikeButton>
      </DialogActions>
    </Dialog>
  )
}

ProjectsTreeDialog.propTypes = {
  onExpand: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projectTree: PropTypes.object,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadingInitialTree: PropTypes.bool.isRequired,
  parentId: PropTypes.string
}

export default ProjectsTreeDialog
