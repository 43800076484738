import React, { useState, useCallback } from 'react'
import { useOidc } from '@axa-fr/react-oidc'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import { Typography } from '@material-ui/core'
import { useTypedSelector } from '../../reducers'
import MikeButton from '@mike/mike-shared-frontend/mike-button/MikeButton'

import './mike-login.css'

interface IProps {
  logInLabel?: string
  logOutLabel?: string
  flat?: boolean
  logoutClickCallback?: any
}

export const LOGGINGOUT = 'loggingOut'

/**
 * @name MikeLogin
 * @summary Login component for use within CloudBridge applications.
 */
const MikeLogin = (props: IProps) => {
  const { logOutLabel, flat, logoutClickCallback } = props
  const { logout } = useOidc()

  const user = useTypedSelector(state => state.auth.user)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [userDetailsShown, setUserDetailsShown] = useState(false)

  const handleLogout = async () => {
    logoutClickCallback()
    sessionStorage.setItem(LOGGINGOUT, 'true')
    logout('/')
  }

  const handleClickAway = useCallback((event: any) => {
    setAnchorEl(null)
    setUserDetailsShown(false)
  }, [])

  const toggleUserDetails = useCallback(
    (event: any) => {
      !userDetailsShown && setAnchorEl(event.currentTarget)
      setUserDetailsShown(!userDetailsShown)
    },
    [userDetailsShown]
  )

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Fab
          onClick={toggleUserDetails}
          color="primary"
          size="small"
          style={{
            fontWeight: 300,
            fontSize: 12,
            minHeight: 0,
            width: 32,
            height: 32,
            boxShadow: flat ? 'none' : undefined
          }}
        >
          {user?.initials || ''}
        </Fab>
        <Popper
          anchorEl={anchorEl}
          placement="bottom-end"
          open={userDetailsShown}
          disablePortal={false}
          onClick={toggleUserDetails}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window'
            }
          }}
          className="UserDetailsPopper"
        >
          <Paper elevation={2} style={{ padding: 16 }}>
            <Grid
              container
              direction="column"
              alignItems="flex-end"
              justifyContent="space-evenly"
              spacing={4}
            >
              <Grid item key="name">
                <Typography>{user?.name}</Typography>
              </Grid>
              <Grid item key="logout">
                <MikeButton
                  buttontype="primary"
                  size="small"
                  onClick={handleLogout}
                >
                  {logOutLabel}
                </MikeButton>
              </Grid>
            </Grid>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default MikeLogin
