import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import Tooltip from '@material-ui/core/Tooltip'
import uniq from 'lodash/uniq'

const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400
  }
})

const useTreeItemStyles = makeStyles({
  root: {
    color: mikePalette.primary.main,
    '&:hover > $content': {
      backgroundColor: mikePalette.secondary.light
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: mikePalette.primary.main,
      color: mikePalette.secondary.contrastText
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    }
  },
  label: {
    color: 'inherit'
  },
  selected: {},
  expanded: {},
  disabled: {
    color: mikePalette.primary.light
  }
})

const ProjectsTree = ({
  onExpand,
  onSelect,
  projectTree,
  selectedIds,
  disabledIds,
  parentId,
  parentIdTooltip,
  tooltip,
  selectedItemTooltip
}) => {
  const classes = useStyles()
  const itemClasses = useTreeItemStyles()

  const allDisabledIds = uniq(
    [parentId].concat(selectedIds).concat(disabledIds)
  )

  const handleChange = (event, nodeIds) => {
    event.preventDefault()
    nodeIds[0] !== 'project' &&
      !selectedIds.includes(nodeIds[0]) &&
      onExpand(nodeIds[0])
  }

  const handleSelect = (event, nodeId) => {
    event.preventDefault()
    const selectedId = allDisabledIds.includes(nodeId) ? null : nodeId
    onSelect(selectedId)
  }

  const getTooltip = id => {
    return parentId === id
      ? parentIdTooltip
      : disabledIds.includes(id)
      ? tooltip
      : selectedIds.includes(id)
      ? selectedItemTooltip
      : null
  }

  const renderTree = children => {
    return (
      children &&
      children.map((child, index) => {
        const childrenNodes =
          projectTree[child.id] && projectTree[child.id].length > 0
            ? renderTree(projectTree[child.id])
            : [<div key={index} />]

        const tip = getTooltip(child.id)
        const disabled = tip !== null

        return (
          <TreeItem
            key={child.id}
            nodeId={child.id}
            label={
              disabled ? (
                <Tooltip title={tip}>
                  <span>{child.name}</span>
                </Tooltip>
              ) : (
                <div>{child.name}</div>
              )
            }
            classes={{
              root: disabled ? itemClasses.disabled : itemClasses.root,
              label: disabled ? itemClasses.disabled : itemClasses.label,
              selected: disabled ? itemClasses.disabled : itemClasses.selected,
              expanded: disabled ? itemClasses.disabled : itemClasses.selected
            }}
          >
            {childrenNodes}
          </TreeItem>
        )
      })
    )
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={projectTree && Object.keys(projectTree)}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleChange}
      onNodeSelect={handleSelect}
    >
      {projectTree && projectTree.project && renderTree(projectTree.project)}
    </TreeView>
  )
}

ProjectsTree.propTypes = {
  projectTree: PropTypes.object,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  parentId: PropTypes.string,
  parentIdTooltip: PropTypes.string.isRequired,
  selectedItemTooltip: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default ProjectsTree
