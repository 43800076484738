import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { compose, withHandlers, withState } from 'recompose'

import { removeError } from '../actions/errors'
import {
  dismissProgressItem,
  showLink,
  openPopupReminder,
  surpressPopupReminder
} from '../actions/ui'

import { createTenant } from '../actions/tenant'

import {
  getUser,
  getErrors,
  getProgressSnackVisibility,
  getNotification,
  getLinkNotification,
  getLinkToProject,
  getLoginConfig,
  getSupportConfig,
  getTutorial,
  getApplicationsNotifications,
  getAppStatus,
  getOpenAppStatus,
  getPopupReminderIsOpen,
  getSurpressPopupReminder,
  getProgressItems,
  getAdminLoadingCredits,
  getAdminCredits,
  getLoadingTenants,
  getTenants,
  getUserIsAdmin,
  getUserisBackOffice,
  getLoadingApplications,
  getApplications,
  getProjectDetails,
  getGroups,
  getTenantFeatures,
  getProject
} from '../state'

import App from '../components/App'

const mapStateToProps = createSelector(
  [
    getUser,
    getProgressSnackVisibility,
    getNotification,
    getLinkNotification,
    getLinkToProject,
    getLoginConfig,
    getSupportConfig,
    getTutorial,
    getErrors,
    getApplicationsNotifications,
    getAppStatus,
    getOpenAppStatus,
    getPopupReminderIsOpen,
    getSurpressPopupReminder,
    getProgressItems,
    getAdminLoadingCredits,
    getAdminCredits,
    getLoadingTenants,
    getTenants,
    getUserIsAdmin,
    getUserisBackOffice,
    getLoadingApplications,
    getApplications,
    getProjectDetails,
    getGroups,
    getTenantFeatures,
    getProject
  ],
  (
    user,
    progressSnackVisible,
    notification,
    linkNotification,
    linkToProject,
    loginConfig,
    support,
    tutorial,
    errors,
    appNotifications,
    appStatus,
    appStatusIsOpen,
    popupReminderIsOpen,
    surpressPopupReminder,
    progressItems,
    loadingCredits,
    credits,
    loadingTenants,
    tenants,
    isAdmin,
    isBackOffice,
    loadingApps,
    apps,
    project,
    groups,
    features,
    currentProject
  ) => ({
    user,
    progressSnackVisible,
    notification,
    linkNotification,
    linkToProject,
    loginConfig,
    support,
    tutorial,
    errors,
    appNotifications,
    appStatus,
    appStatusIsOpen,
    popupReminderIsOpen,
    surpressPopupReminder,
    progressItems,
    loadingCredits,
    credits,
    loadingTenants,
    tenants,
    isAdmin,
    isBackOffice,
    loadingApps,
    apps,
    project,
    groups,
    features,
    currentProject
  })
)

const enhance = compose(
  connect(mapStateToProps),

  withState('openCreateTenant', 'setOpenCreateTenant', false),

  withHandlers({
    onCreateTenantCloseClick: props => () => props.setOpenCreateTenant(false),

    onOpenCreateTenant: props => () => props.setOpenCreateTenant(true),

    onCreateTenant: props => data => {
      props.dispatch(createTenant(data))
      props.setOpenCreateTenant(false)
    },

    onDismissProgressListItem: ({ dispatch }) => ({ id }) =>
      dispatch(dismissProgressItem(id)),

    onCloseLinkNotification: ({ dispatch }) => () =>
      dispatch(showLink(null, null)),

    onCloseError: ({ dispatch }) => id => dispatch(removeError(id)),

    onClosePopupReminder: props => () =>
      props.dispatch(openPopupReminder(false)),

    onSurpressPopupReminder: props => surpress => {
      localStorage.setItem(
        'supressActivatePopupReminder',
        surpress ? 'true' : 'false'
      )
      props.dispatch(surpressPopupReminder(surpress))
    }
  })
)

export default enhance(App)
