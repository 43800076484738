import IAPIAccess from '../model/IAPIAccess'
import IAPIKey from '../model/IAPIKey'

export const LOAD_API_ACCESS = 'API_ACCESS/LOAD'
export type LoadAPIAccessListType = {
  type: string
  tenantId: string
}
export const loadAPIAccessList = tenantId =>
  ({
    type: LOAD_API_ACCESS,
    tenantId
  } as LoadAPIAccessListType)

export const SET_API_ACCESS = 'API_ACCESS/SET'
export const setApiAccessList = (apiAccess: IAPIAccess[]) => ({
  type: SET_API_ACCESS,
  apiAccess
})

export type CreateUpdateAPIAccessType = {
  type: string
  tenantId: string
  apiAccess: IAPIAccess
}
export const CREATE_API_ACCESS = 'API_ACCESS/CREATE'
export const createAPIAccess = (apiAccess: IAPIAccess, tenantId: string) =>
  ({
    type: CREATE_API_ACCESS,
    apiAccess,
    tenantId
  } as CreateUpdateAPIAccessType)

export const UPDATE_API_ACCESS = 'API_ACCESS/UPDATE'
export const updateAPIAccess = (apiAccess: IAPIAccess, tenantId: string) =>
  ({
    type: UPDATE_API_ACCESS,
    apiAccess,
    tenantId
  } as CreateUpdateAPIAccessType)

export const DELETE_API_ACCESS = 'API_ACCESS/DELETE'
export type DeleteAPIAccessType = {
  type: string
  tenantId: string
  apiAccessList: IAPIAccess[]
}
export const deleteAPIAccess = (
  apiAccessList: IAPIAccess[],
  tenantId: string
) =>
  ({
    type: DELETE_API_ACCESS,
    apiAccessList,
    tenantId
  } as DeleteAPIAccessType)

export type CreateUpdateAPIKeyType = {
  type: string
  tenantId: string
  apiKey: IAPIKey
  apiAccess: IAPIAccess
}
export const CREATE_API_KEY = 'API_KEY/CREATE'
export const createAPIKey = (
  apiKey: IAPIKey,
  apiAccess: IAPIAccess,
  tenantId: string
) =>
  ({
    type: CREATE_API_KEY,
    apiKey,
    apiAccess,
    tenantId
  } as CreateUpdateAPIKeyType)

export const UPDATE_API_KEY = 'API_KEY/UPDATE'
export const updateAPIKey = (
  apiKey: IAPIKey,
  apiAccess: IAPIAccess,
  tenantId: string
) =>
  ({
    type: UPDATE_API_KEY,
    apiKey,
    apiAccess,
    tenantId
  } as CreateUpdateAPIKeyType)

export const DELETE_API_KEYS = 'API_KEY/DELETE'
export type DeleteAPIKeysType = {
  type: string
  tenantId: string
  apiKeyList: IAPIKey[]
}
export const deleteAPIKeys = (apiKeyList: IAPIKey[], tenantId: string) =>
  ({
    type: DELETE_API_KEYS,
    tenantId,
    apiKeyList
  } as DeleteAPIKeysType)
