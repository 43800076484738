import React from 'react'
import {
  makeStyles,
  createStyles,
  Popover,
  Typography
} from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import ButtonBase from '@material-ui/core/ButtonBase'

import { ReactComponent as Info } from '@mike/mike-shared-frontend/media/icons/Info'
import { iconPrimaryStyle } from '../buttons/iconStyles'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: (props: IProps) => ({
      cursor: props.disabled ? 'default' : 'pointer'
    }),
    popover: {
      maxWidth: '75vw'
    },
    content: {
      padding: theme.spacing(2)
    }
  })
})

interface IProps {
  helpTexts: string[]
  disabled?: boolean
}

const ContextHelp = (props: IProps) => {
  const classes = useStyles(props)
  const { helpTexts, disabled } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    !disabled && setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'context-help' : undefined

  return (
    <>
      <ButtonBase
        className={classes.button}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Info className={iconPrimaryStyle(disabled)} />
      </ButtonBase>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.content}>
          {helpTexts.map((helpText, index) => {
            return <Typography key={index}>{helpText}</Typography>
          })}
        </div>
      </Popover>
    </>
  )
}

export default ContextHelp
