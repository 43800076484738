import ITenantUser from '../model/ITenantUser'

export type LoadUsersType = {
  type: string
  tenantId: string
}
export const LOAD_USERS = 'USERS/LOAD'
export const loadUsers = (tenantId: string): LoadUsersType => ({
  type: LOAD_USERS,
  tenantId
})

export type DeleteUsersType = {
  type: string
  users: ITenantUser[]
  tenantId: string
}
export const DELETE_USERS = 'USERS/DELETE'
export const deleteUsers = (
  users: ITenantUser[],
  tenantId: string
): DeleteUsersType => ({
  type: DELETE_USERS,
  users,
  tenantId
})

export type UpdateUserType = {
  type: string
  user: ITenantUser
  tenantId: string
}
export const UPDATE_USER = 'USERS/UPDATE'
export const updateUser = (
  user: ITenantUser,
  tenantId: string
): UpdateUserType => ({
  type: UPDATE_USER,
  user,
  tenantId
})

export type InviteUserType = {
  type: string
  email: string
  role: string
  tenantId: string
}
export const INVITE_USER = 'USERS/INVITE'
export const inviteUser = (
  email: string,
  role: string,
  tenantId: string
): InviteUserType => ({
  type: INVITE_USER,
  email,
  role,
  tenantId
})
