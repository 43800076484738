import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { useTypedSelector } from '../../reducers'
import moment from 'moment'
import {
  deleteAPIAccess,
  deleteAPIKeys,
  loadAPIAccessList
} from '../../actions/apiAccess'
import { useDispatch } from 'react-redux'
import {
  Input,
  InputAdornment,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core'
import SearchInput from '../SearchInput'
import { defineMessages, useIntl } from 'react-intl'
import RefreshButton from '../buttons/RefreshButton'
import DeleteButton from '../buttons/DeleteButton'
import DataTable from '../DataTable'
import UsersNav from '../UsersNav'
import IconButton from '../buttons/IconButton'
import APIAccessForm from '../APIAccessForm'
import APIKeyForm from '../APIKeyForm'
import { MikeSlidingPanel } from '@mike/mike-shared-frontend'
import IAPIAccess from '../../model/IAPIAccess'
import IAPIKey from '../../model/IAPIKey'
import { createAPIAccess, updateAPIAccess } from '../../actions/apiAccess'
import { createAPIKey, updateAPIKey } from '../../actions/apiAccess'
import { StateEnum } from '../../model/StateEnum'
import LongName from '../DataTable/renders/LongName'
import Renders from '../DataTable/renders'
import messages from '../../shared/messages'
import { flatten } from 'lodash'
import { ReactComponent as Duplicate } from '@mike/mike-shared-frontend/media/icons/Duplicate'
import Button from '@material-ui/core/Button'
import copy from 'clipboard-copy'
import { css } from 'emotion'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import { setLastAddedAPIKey } from '../../reducers/apiKeys'
import { ReactComponent as Alert } from '@mike/mike-shared-frontend/media/icons/Alert'

const localMessages = defineMessages({
  title: {
    id: 'screens.apiAccess.title'
  },
  confirmationDialogTitle: {
    id: 'screens.apiAccess.confirmationDialog.title'
  },
  confirmationDialogMessage: {
    id: 'screens.apiAccess.confirmationDialog.message'
  },
  searchPlaceholder: {
    id: 'screens.apiAccess.input.search.placeholder'
  },
  createApiKey: {
    id: 'screens.apiAccess.button.createApiKey'
  },
  key: {
    id: 'screens.apiAccess.apiKey'
  },
  expirationDate: {
    id: 'screens.apiAccess.expirationDate'
  },
  keyGeneratedTitle: {
    id: 'screens.apiAccess.keyGenerated.title'
  },
  keyGeneratedMessage: {
    id: 'screens.apiAccess.keyGenerated.message'
  }
})

const hoverStyle = () => {
  return css`
    &:hover {
      cursor: pointer;
    }
  `
}

const useStyles = makeStyles(theme => ({
  tableTopHeader: {
    position: 'sticky',
    top: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: '#f2f5f7'
  },
  tableTopActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  leftActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  rightActionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  apiAccountRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  },
  apiKeyRow: {
    paddingLeft: '20px'
  },
  expirationRow: {
    display: 'flex'
  },
  dialogTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  dialogContent: {
    maxWidth: 378,
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}))

const FIRST_PAGE = 0

const APIAccess = props => {
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId) || ''
  const apiAccessList = useTypedSelector(state => state.apiAccess.apiAccessList)
  const apiKeyList = useTypedSelector(state => state.apiKeys.apiKeyList)
  const latestAddedApiKey = useTypedSelector(
    state => state.apiKeys.lastAddedApiKey
  )
  const loadingApiAccess =
    useTypedSelector(state => state.apiAccess.state) === StateEnum.LOADING
  const dispatch = useDispatch()
  const intl = useIntl()
  const [searchText, setSearchText] = useState<string>('')
  const classes = useStyles(props)
  const [openAPIAccessEditor, setOpenAPIAccessEditor] = useState(false)
  const [selectedRows, setSelectedRows] = useState<(IAPIAccess & IAPIKey)[]>([])
  const [sortOrder, setSortOrder] = useState<string[]>(['asc'])
  const [sortOrderBy, setSortOrderBy] = useState<string[]>(['name'])
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
    false
  )
  const [itemsToBeDeleted, setItemsToBeDeleted] = useState<
    (IAPIAccess & IAPIKey)[]
  >([])
  const [currentEditingApiAccess, setCurrentEditingApiAccess] = useState<
    IAPIAccess
  >({
    name: ''
  })
  const [openKeyEditor, setOpenKeyEditor] = useState(false)
  const [currentEditingKey, setCurrentEditingKey] = useState<IAPIKey>({
    name: ''
  })

  const projectNameRender = (value, item: IAPIAccess & IAPIKey) => {
    if (item && item.key) {
      return (
        <div className={classes.apiKeyRow}>
          <LongName longName={value} />
        </div>
      )
    }
    return (
      <div className={classes.apiAccountRow}>
        <LongName longName={value} variant={'bold'} />
        <Button
          variant="outlined"
          size="small"
          onClick={event => {
            event.stopPropagation()
            setOpenKeyEditor(true)
            setCurrentEditingApiAccess(item)
            setCurrentEditingKey({ name: '' })
          }}
        >
          {intl.formatMessage(localMessages.createApiKey)}
        </Button>
      </div>
    )
  }

  const projectKeyRender = (value, item: IAPIAccess & IAPIKey) => {
    if (item && item.key) {
      return <LongName longName={value} />
    }
  }

  const projectExpirationDateRender = (value, item: IAPIAccess & IAPIKey) => {
    if (item && item.key) {
      const local = moment.utc(value).local()
      const soonDate = moment(new Date()).add(7, 'days')

      return (
        <div className={classes.expirationRow}>
          <LongName
            longName={Intl.DateTimeFormat(navigator.language, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            }).format(local.toDate())}
          />
          {local < soonDate && <Alert />}
        </div>
      )
    }
  }

  useEffect(() => {
    dispatch(loadAPIAccessList(tenantId))
  }, [dispatch, tenantId])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 8)
    }
  }, [])

  const onRefresh = () => {
    dispatch(loadAPIAccessList(tenantId))
  }

  const onDelete = item => {
    setItemsToBeDeleted([item])
    setOpenDeleteConfirmation(true)
  }

  const onDeleteItems = () => {
    setItemsToBeDeleted(selectedRows)
    setOpenDeleteConfirmation(true)
  }

  const onColumnClick = (column: IAPIAccess & IAPIKey) => {
    if (column && column.key) {
      const apiAccess = apiAccessList.find(
        item => item.accountId === column.accountId
      )
      apiAccess && setCurrentEditingApiAccess(apiAccess)
      setCurrentEditingKey(column)
      setOpenKeyEditor(true)
    } else {
      setCurrentEditingApiAccess(column)
      setOpenAPIAccessEditor(true)
    }
  }

  const onHandleRequestSort = (orderBy: string, order: string) => {
    setSortOrder([order])
    setSortOrderBy([orderBy])
  }

  const sorterdAPIAccesList: (IAPIAccess | IAPIKey)[] = useMemo(() => {
    if (searchText?.length === 0) {
      return flatten(
        apiAccessList.map(apiAccess => {
          if (apiAccess.accountId) {
            return [apiAccess].concat(apiKeyList[apiAccess.accountId] || [])
          }
          return apiAccess
        })
      )
    }

    const lowerSearch = searchText.toLowerCase()

    return flatten(
      apiAccessList.map(apiAccess => {
        if (apiAccess.accountId === undefined) return []

        if (apiAccess.name.toLowerCase().includes(lowerSearch)) {
          return [apiAccess].concat(apiKeyList[apiAccess.accountId] || [])
        }

        const filteredKeys = apiKeyList[apiAccess.accountId]?.filter(
          key =>
            key.name.toLowerCase().includes(lowerSearch) ||
            key.key.toLowerCase().includes(lowerSearch)
        )

        if (filteredKeys?.length) {
          return [apiAccess].concat(filteredKeys)
        }

        return []
      })
    )
  }, [apiAccessList, apiKeyList, searchText])

  const onOkDeleteItems = () => {
    const apiAccess = itemsToBeDeleted.filter(
      item => item && !item.key
    ) as IAPIAccess[]
    const apiAccessIds = apiAccess.map(item => item.accountId)
    const apiKeys = itemsToBeDeleted.filter(
      item => item && item.key && !apiAccessIds.includes(item.accountId)
    ) as IAPIKey[]

    if (apiAccess.length > 0) {
      dispatch(deleteAPIAccess(apiAccess, tenantId))
    }
    if (apiKeys.length > 0) {
      dispatch(deleteAPIKeys(apiKeys, tenantId))
    }
    setSelectedRows([])
    setOpenDeleteConfirmation(false)
  }

  const onCancelDeleteItems = () => {
    setOpenDeleteConfirmation(false)
  }

  const onOkLatestAddedApiKey = () => {
    dispatch(setLastAddedAPIKey({ apiKey: null }))
  }

  const handleCopyApiKey = () => {
    if (latestAddedApiKey?.key) {
      copy(latestAddedApiKey.key)
    }
  }

  const getIdForField = (item: IAPIAccess & IAPIKey) => {
    if (item && item.key) {
      return item.publicId
    }
    return item.accountId
  }

  return (
    <>
      <UsersNav />
      {latestAddedApiKey && (
        <ConfirmationDialog
          open={latestAddedApiKey !== null}
          ok={intl.formatMessage(messages.okay)}
          hideCancelButton={true}
          onOk={onOkLatestAddedApiKey}
          onCancel={onOkLatestAddedApiKey}
        >
          <MuiDialogContent className={classes.dialogContent}>
            <Typography variant="h3" className={classes.dialogTitle}>
              {intl.formatMessage(localMessages.keyGeneratedTitle)}
            </Typography>
            <Box mt={2}>
              <Typography variant="body2">
                {intl.formatMessage(localMessages.keyGeneratedMessage)}
              </Typography>
            </Box>
            <Box mt={2}>
              <Input
                type="text"
                value={latestAddedApiKey.key}
                inputProps={{ readOnly: true }}
                fullWidth={true}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      onClick={handleCopyApiKey}
                    >
                      <Duplicate />
                    </Button>
                  </InputAdornment>
                }
              />
            </Box>
          </MuiDialogContent>
        </ConfirmationDialog>
      )}
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={intl.formatMessage(localMessages.confirmationDialogTitle, {
          itemCount: itemsToBeDeleted.length
        })}
        message={intl.formatMessage(localMessages.confirmationDialogMessage, {
          itemCount: itemsToBeDeleted.length
        })}
        ok={intl.formatMessage(messages.yesDelete)}
        onOk={onOkDeleteItems}
        onCancel={onCancelDeleteItems}
      />
      <div className={classes.tableTopHeader} ref={measuredRef}>
        <Typography variant={'h4'}>
          {intl.formatMessage(localMessages.title)}
        </Typography>
        <div className={classes.tableTopActionButtons}>
          <div className={classes.leftActionButtons}>
            <SearchInput
              id="api-access-search-input"
              placeholder={intl.formatMessage(localMessages.searchPlaceholder)}
              autoFocus
              text={searchText}
              onTextChange={setSearchText}
            />

            <RefreshButton onClick={onRefresh} />

            {selectedRows.length > 0 && (
              <DeleteButton onClick={onDeleteItems} />
            )}
          </div>
          <div className={classes.rightActionButtons}>
            <IconButton
              messageId="screens.apiAccess.createApiAccount"
              onClick={() => {
                setCurrentEditingApiAccess({ name: '' })
                setOpenAPIAccessEditor(true)
              }}
            />
          </div>
        </div>
      </div>

      <DataTable
        actions={[
          {
            name: 'Edit',
            callBack: onColumnClick,
            render: Renders.renderEdit,
            disableGutters: true
          },
          {
            name: 'Delete',
            callBack: onDelete,
            render: Renders.renderDelete,
            disableGutters: true
          }
        ]}
        loading={loadingApiAccess}
        columns={[
          {
            field: 'name',
            label: intl.formatMessage(messages.name),
            render: projectNameRender,
            className: hoverStyle
          },
          {
            field: 'key',
            label: intl.formatMessage(localMessages.key),
            render: projectKeyRender,
            className: hoverStyle
          },
          {
            field: 'expirationDate',
            label: intl.formatMessage(localMessages.expirationDate),
            render: projectExpirationDateRender,
            className: hoverStyle
          }
        ]}
        idField={getIdForField}
        data={sorterdAPIAccesList}
        onColumnClick={onColumnClick}
        onHandleRequestSort={onHandleRequestSort}
        onSelectionChange={setSelectedRows}
        selectedRows={selectedRows}
        isSelected={(item: IAPIAccess & IAPIKey) => {
          if (item && item.key) {
            return Boolean(
              selectedRows.find(row => {
                if (row && row.key) {
                  return row.publicId === item.publicId
                }
                return row.accountId === item.accountId
              })
            )
          } else {
            const id = getIdForField(item)
            return Boolean(selectedRows.find(row => getIdForField(row) === id))
          }
        }}
        topOffset={topOffset}
        totalCount={sorterdAPIAccesList.length}
        page={FIRST_PAGE}
        rowsPerPage={sorterdAPIAccesList.length}
        _order={sortOrder}
        _orderBy={sortOrderBy}
      />

      <MikeSlidingPanel
        position="right"
        isOpen={openKeyEditor}
        onClose={() => {
          setOpenKeyEditor(false)
        }}
        titleArea={
          currentEditingKey.accountId
            ? intl.formatMessage({
                id: 'screens.apiAccess.updateApiKey'
              })
            : intl.formatMessage({
                id: 'screens.apiAccess.createApiKey'
              })
        }
        contentArea={
          <APIKeyForm
            initialValues={currentEditingKey}
            handleSubmit={(data: IAPIKey) => {
              if (currentEditingKey.accountId) {
                dispatch(updateAPIKey(data, currentEditingApiAccess, tenantId))
              } else {
                dispatch(createAPIKey(data, currentEditingApiAccess, tenantId))
              }
              setOpenKeyEditor(false)
            }}
          />
        }
        actionsArea={null}
        noGrayOverlay={false}
      />
      <MikeSlidingPanel
        position="right"
        isOpen={openAPIAccessEditor}
        onClose={() => {
          setOpenAPIAccessEditor(false)
        }}
        titleArea={
          currentEditingApiAccess.accountId
            ? intl.formatMessage({
                id: 'screens.apiAccess.updateApiAccount'
              })
            : intl.formatMessage({
                id: 'screens.apiAccess.createApiAccount'
              })
        }
        contentArea={
          <APIAccessForm
            initialValues={currentEditingApiAccess}
            handleSubmit={(data: IAPIAccess) => {
              if (currentEditingApiAccess.accountId) {
                dispatch(updateAPIAccess(data, tenantId))
              } else {
                dispatch(createAPIAccess(data, tenantId))
              }
              setOpenAPIAccessEditor(false)
            }}
          />
        }
        actionsArea={null}
        noGrayOverlay={false}
      />
    </>
  )
}

export default APIAccess
