import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import first from 'lodash/first'
import map from 'lodash/map'
import find from 'lodash/find'
import { isNumeric } from '../helpers/common'

import { loadCustomerUsers } from '../actions/customerUsers'
import {
  loadProjectDetails,
  loadProjectSubprojects,
  deleteProjectDatasets,
  updateProjectDataset,
  deleteSubProjects,
  updateProjectDetails,
  moveContent,
  loadProjectRoot,
  resetProjectInitialTree,
  loadProjectPath,
  setProjectPagination,
  loadProjectContent,
  setProjectRowsPerPage
} from '../actions/project'

import { loadDatasetReaders } from '../actions/datasetReaders'
import { loadDatasetWriters } from '../actions/datasetWriters'

import { createNewFolder } from '../actions/projects'

import { addError } from '../actions/errors'

import {
  uploadAndImportFile,
  uploadAndImportManyFiles
} from '../actions/uploadAndImport'
import {
  exportAndDownloadDataset,
  exportAndDownloadManyDatasets
} from '../actions/exportAndDownload'
import { convertDataset } from '../actions/converts'

import { DOWNLOAD_TYPE } from '../helpers/export'
import { UPLOAD_TYPE } from '../helpers/upload'
import { CONVERT_TYPE } from '../helpers/convert'
import asIfReaderWriter from '../components/DatasetConversionDialog/model/asIfReaderWriter'

import {
  getUser,
  getCustomerUsers,
  getLoadingCustomerUsers,
  getProjectDetails,
  getProjectContent,
  getLoadingProjectDetails,
  getLoadingProjectInitialTree,
  getProjectTree,
  getLoadingProjectPath,
  getProjectPath,
  getDatasetReaders,
  getDatasetFileReaders,
  getLoadingDatasetReaders,
  getDatasetWriters,
  getDatasetDedicatedWriters,
  getLoadingDatasetWriters,
  getLoadingApplications,
  getApplications,
  getCoordinateSystemsLoading,
  getSelectedCoordinateSystems,
  getSearchCoordinateSystemsById,
  getProjectContentPagination,
  getProjectContentRowsPerPage,
  getProjectContentNamePrefix,
  getProjectContentSortBy,
  getProjectContentSortOrder,
  getTenantFeatures
} from '../state'

import ProjectContent from '../components/ProjectContent'
import { isDataset } from '../helpers/projectContent'
import {
  filterCoordinateSystems,
  getCoordinateSystemsById
} from '../actions/coordinateSystems'

const mapStateToProps = createSelector(
  [
    getUser,
    getCustomerUsers,
    getLoadingCustomerUsers,
    getProjectDetails,
    getProjectContent,
    getLoadingProjectDetails,
    getLoadingProjectInitialTree,
    getProjectTree,
    getLoadingProjectPath,
    getProjectPath,
    getDatasetReaders,
    getDatasetFileReaders,
    getLoadingDatasetReaders,
    getDatasetWriters,
    getDatasetDedicatedWriters,
    getLoadingDatasetWriters,
    getLoadingApplications,
    getApplications,
    getCoordinateSystemsLoading,
    getSelectedCoordinateSystems,
    getSearchCoordinateSystemsById,
    getProjectContentPagination,
    getProjectContentRowsPerPage,
    getProjectContentNamePrefix,
    getProjectContentSortBy,
    getProjectContentSortOrder,
    getTenantFeatures
  ],
  (
    user,
    customerUsers,
    loadingCustomerUsers,
    project,
    projectContent,
    loadingProjectDetails,
    loadingInitialTree,
    projectTree,
    loadingProjectPath,
    path,
    datasetReaders,
    datasetFileReaders,
    showDatasetReadersLoader,
    datasetWriters,
    dedicatedWriters,
    showDatasetWritersLoader,
    loadingApps,
    apps,
    loadingCoordinateSystems,
    selectedCoordinateSystems,
    searchCoordinateSystemsById,
    page,
    rowsPerPage,
    namePrefix,
    sortBy,
    sortOrder,
    features
  ) => ({
    user,
    customerUsers,
    project,
    projectContent,
    loading:
      loadingProjectDetails || loadingCustomerUsers || loadingProjectPath,
    loadingInitialTree,
    projectTree,
    path,
    datasetReaders,
    datasetFileReaders,
    showDatasetReadersLoader,
    datasetWriters,
    dedicatedWriters,
    showDatasetWritersLoader,
    loadingApps,
    apps,
    loadingCoordinateSystems,
    selectedCoordinateSystems,
    searchCoordinateSystemsById,
    page,
    rowsPerPage,
    namePrefix,
    sortBy,
    sortOrder,
    features
  })
)

const enhance = compose(
  withRouter,

  connect(mapStateToProps, dispatch => ({ dispatch })),
  withState('searchText', 'setSearchText', ''),
  withState('selectedItems', 'setSelectedItems', []),
  withState('openCreateProject', 'setOpenCreateProject', false),
  withState('openDeleteConfirmation', 'setOpenDeleteConfirmation', false),
  withState('openDownloadManyDialog', 'setOpenDownloadManyDialog', false),
  withState('openEditDataset', 'setOpenEditDataset', false),
  withState('openImportDialog', 'setOpenImportDialog', false),
  withState('openUploadManyDialog', 'setOpenUploadManyDialog', false),
  withState('editableDataset', 'setEditableDataset'),
  withState('editingCurrentProject', 'setEditingCurrentProject', false),
  withState('createSubProject', 'setCreateSubProject', false),
  withState('openProjectTreeDialog', 'setOpenProjectTreeDialog', false),

  lifecycle({
    componentDidMount() {
      const projectId = this.props.match.params.id
      this.props.dispatch(loadProjectDetails(projectId))
      this.props.dispatch(loadCustomerUsers(projectId))
      this.props.dispatch(loadDatasetReaders())
      this.props.dispatch(loadDatasetWriters())
    },
    componentDidUpdate(prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        const projectId = this.props.match.params.id
        this.props.dispatch(loadProjectDetails(projectId))
      }
    }
  }),

  withHandlers({
    onSearchProjection: props => id => {
      isNumeric(id)
        ? props.dispatch(getCoordinateSystemsById(id))
        : props.dispatch(filterCoordinateSystems(id))
    },

    onError: props => (name, error) => {
      props.dispatch(addError(name, error))
    },

    onRefreshClick: props => () => {
      props.dispatch(
        loadProjectContent(
          props.match.params.id,
          props.page,
          props.rowsPerPage,
          props.orderBy,
          props.order,
          props.namePrefix
        )
      )
    },

    // Open side panel to edit the selected dataset
    onEdit: props => item => {
      if (item) {
        if (isDataset(item)) {
          props.setEditableDataset(item)
          props.setOpenEditDataset(true)
        }
      } else {
        // find a fresh copy of the dataset before editing it
        const dataset = find(props.datasets, {
          id: first(props.selectedItems).id
        })
        props.setEditableDataset(dataset)
        props.setOpenEditDataset(true)
      }
    },

    onUpdateDataset: props => data => {
      props.dispatch(updateProjectDataset(props.project.id, data))
      props.setOpenEditDataset(false)
    },

    onDelete: props => itemsToBeDeleted => {
      const ids = map(itemsToBeDeleted, 'id')
      props.setSelectedItems(
        props.selectedItems.filter(item => {
          return !ids.includes(item.id)
        })
      )
      const datasetIds = map(
        itemsToBeDeleted.filter(item => 'datasetType' in item),
        'id'
      )
      const subprojectIds = map(
        itemsToBeDeleted.filter(item => !('datasetType' in item)),
        'id'
      )
      if (datasetIds.length > 0) {
        props.dispatch(deleteProjectDatasets(props.project.id, datasetIds))
      }

      if (subprojectIds.length > 0) {
        props.dispatch(deleteSubProjects(props.project.id, subprojectIds))
      }
      props.setOpenDeleteConfirmation(false)
    },

    onUploadMany: props => filesToBeUploaded => {
      if (filesToBeUploaded.length > 0) {
        props.dispatch(
          uploadAndImportManyFiles(props.project.id, filesToBeUploaded)
        )
      }
      props.setOpenUploadManyDialog(false)
    },

    onDownloadMany: props => datasetsToBeDownloaded => {
      if (datasetsToBeDownloaded.length > 0) {
        props.dispatch(
          exportAndDownloadManyDatasets(datasetsToBeDownloaded, {
            reader: asIfReaderWriter.reader,
            writer: asIfReaderWriter.writer
          })
        )
      }
      props.setOpenDownloadManyDialog(false)
    },

    onSelectionChange: props => selection => {
      props.setSelectedItems(selection)
    },

    onSearchTextChange: props => searchText => {
      props.setSearchText(searchText)
      props.dispatch(setProjectPagination(0))
      props.dispatch(
        loadProjectContent(
          props.project.id,
          0,
          props.rowsPerPage,
          props.orderBy,
          props.order,
          searchText
        )
      )
    },

    onChangePage: props => page => {
      props.dispatch(
        loadProjectContent(
          props.project.id,
          page,
          props.rowsPerPage,
          props.orderBy,
          props.order,
          props.namePrefix
        )
      )
      props.dispatch(setProjectPagination(page))
    },

    onChangeRowsPerPage: props => rowsPerPage => {
      props.dispatch(setProjectRowsPerPage(rowsPerPage))
      props.dispatch(
        loadProjectContent(
          props.project.id,
          props.page,
          rowsPerPage,
          props.orderBy,
          props.order,
          props.namePrefix
        )
      )
    },

    onImportDialogDone: props => (data, mode, datasetId) => {
      switch (mode) {
        case UPLOAD_TYPE:
          props.dispatch(uploadAndImportFile(props.project.id, data))
          break
        case CONVERT_TYPE:
          props.dispatch(
            convertDataset(
              props.project.id,
              datasetId,
              data,
              data.importData.name
            )
          )
          break
        case DOWNLOAD_TYPE:
          props.dispatch(
            exportAndDownloadDataset(
              data.importData.name,
              datasetId,
              props.project.id,
              data
            )
          )
          break
        default:
          break
      }

      props.setOpenImportDialog(false)
    },

    // Parent project is to be edited
    onEditCurrentProject: props => () => {
      props.setCreateSubProject(false)
      props.setEditingCurrentProject(true)
      props.setOpenCreateProject(true)
    },

    // Action button to create new folder has been clicked
    onCreateProjectOpenClick: props => () => {
      props.setCreateSubProject(true)
      props.setEditingCurrentProject(false)
      props.setOpenCreateProject(true)
    },

    // When submit of side panel has been clicked we need to distinguish if
    // Either the parent project shall be updated or a new folder shall be created
    onCreateProject: props => data => {
      if (props.editingCurrentProject) {
        props.dispatch(updateProjectDetails(data))
        props.dispatch(loadProjectPath(props.project.id))
      } else {
        // OnCreate backend fails if members include logged in user
        data.members = data.members.filter(member => {
          return member.userId !== props.user.id
        })
        data.parentProjectId = props.project.id
        props.dispatch(
          createNewFolder(data, () => {
            props.dispatch(
              loadProjectContent(
                props.project.id,
                props.page,
                props.rowsPerPage,
                props.orderBy,
                props.order,
                props.namePrefix
              )
            )
            props.dispatch(loadProjectPath(props.project.id))
          })
        )
      }

      props.setOpenCreateProject(false)
    },

    onCreateProjectCloseClick: props => () => props.setOpenCreateProject(false),

    onMoveToClick: props => () => {
      props.dispatch(
        loadProjectRoot(
          props.subprojects && props.subprojects.length > 0
            ? props.subprojects[0]
            : props.project
        )
      )
      props.setOpenProjectTreeDialog(true)
    },

    onMoveTo: props => parentProjectId => {
      props.setSelectedItems([])

      const subprojectIds = map(
        props.selectedItems.filter(item => !('datasetType' in item)),
        'id'
      )

      const datasetIds = map(
        props.selectedItems.filter(item => 'datasetType' in item),
        'id'
      )

      if (subprojectIds.length > 0 || datasetIds.length > 0) {
        props.dispatch(
          moveContent(
            parentProjectId,
            subprojectIds,
            datasetIds,
            props.project.id
          )
        )
      }

      props.dispatch(resetProjectInitialTree)
      props.setOpenProjectTreeDialog(false)
    },

    onExpand: props => nodeId => {
      nodeId &&
        nodeId !== 'project' &&
        props.dispatch(loadProjectSubprojects(nodeId, false))
    },

    onCloseTreeDialog: props => () => {
      props.dispatch(resetProjectInitialTree)
      props.setOpenProjectTreeDialog(false)
    },

    onHandleRequestSort: props => (newOrderBy, newOrder) => {
      props.dispatch(setProjectPagination(0))
      props.dispatch(
        loadProjectContent(
          props.project.id,
          0,
          props.rowsPerPage,
          newOrderBy,
          newOrder,
          props.namePrefix
        )
      )
    }
  })
)

export default enhance(ProjectContent)
