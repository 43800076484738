import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  CreateUpdateAPIAccessType,
  CREATE_API_ACCESS,
  DeleteAPIAccessType,
  DELETE_API_ACCESS,
  LoadAPIAccessListType,
  LOAD_API_ACCESS,
  UPDATE_API_ACCESS,
  loadAPIAccessList,
  CREATE_API_KEY,
  UPDATE_API_KEY,
  CreateUpdateAPIKeyType,
  DELETE_API_KEYS,
  DeleteAPIKeysType
} from '../actions/apiAccess'

import { addError } from '../actions/errors'
import {
  getAccounts,
  postAccount,
  patchAccount,
  deleteAccounts,
  postAPIKey,
  patchAPIKey,
  getApiKeys,
  deleteAPIKeys,
  postUser
} from '../apis/admin'
import IAPIAccess from '../model/IAPIAccess'
import {
  setApiAccessLoadingState,
  setAPIAccesssList,
  deleteAPIAccessList,
  addAPIAccessList
} from '../reducers/apiAccess'
import {
  addAPIKeyList,
  deleteAPIKeyList,
  setAPIKeysList,
  setLastAddedAPIKey
} from '../reducers/apiKeys'
import { flatten } from 'lodash'
import { ROLE_TYPES_EXT } from '../helpers/project'

export default function* watchAdmin() {
  yield takeEvery(LOAD_API_ACCESS, fetchApiAccess)
  yield takeEvery(CREATE_API_ACCESS, createApiAccess)
  yield takeEvery(UPDATE_API_ACCESS, updateApiAccess)
  yield takeEvery(DELETE_API_ACCESS, deleteApiAccess)
  yield takeEvery(CREATE_API_KEY, createApiKey)
  yield takeEvery(UPDATE_API_KEY, updateApiKey)
  yield takeEvery(DELETE_API_KEYS, deleteApiKeys)
}

export function* fetchApiAccess(action: LoadAPIAccessListType) {
  if (action.tenantId === '') {
    return
  }

  try {
    yield put(setApiAccessLoadingState({ loadingState: true }))

    const response = yield call(getAccounts, action.tenantId)

    if (response?.data) {
      yield put(setAPIAccesssList({ apiAccess: response.data }))
    }

    const keyList = yield all(
      response.data.map((account: IAPIAccess) =>
        call(getApiKeys, action.tenantId, account)
      )
    )
    yield put(
      setAPIKeysList({ apiKeys: flatten(keyList.map(item => item.data)) })
    )
  } catch (error) {
    yield put(addError(LOAD_API_ACCESS, error))
  }
}

export function* createApiAccess(action: CreateUpdateAPIAccessType) {
  try {
    const response = yield call(postAccount, action.tenantId, action.apiAccess)

    if (action.apiAccess.role !== ROLE_TYPES_EXT.NONE) {
      yield call(
        postUser,
        { userId: response.accountId, role: action.apiAccess.role },
        action.tenantId
      )
    }
    yield put(loadAPIAccessList(action.tenantId))
  } catch (error) {
    yield put(addError(CREATE_API_ACCESS, error))
  }
}

export function* updateApiAccess(action: CreateUpdateAPIAccessType) {
  try {
    yield call(patchAccount, action.tenantId, action.apiAccess)
    yield put(loadAPIAccessList(action.tenantId))
  } catch (error) {
    yield put(addError(CREATE_API_ACCESS, error))
  }
}

export function* deleteApiAccess(action: DeleteAPIAccessType) {
  try {
    yield put(deleteAPIAccessList({ apiAccessList: action.apiAccessList }))
    yield call(deleteAccounts, action.tenantId, action.apiAccessList)
  } catch (error) {
    yield put(addAPIAccessList({ apiAccessList: action.apiAccessList }))
    yield put(addError(CREATE_API_ACCESS, error))
  }
}

export function* createApiKey(action: CreateUpdateAPIKeyType) {
  try {
    const result = yield call(
      postAPIKey,
      action.tenantId,
      action.apiAccess,
      action.apiKey
    )
    yield put(setLastAddedAPIKey({ apiKey: result }))
    yield put(loadAPIAccessList(action.tenantId))
  } catch (error) {
    yield put(addError(CREATE_API_ACCESS, error))
  }
}

export function* updateApiKey(action: CreateUpdateAPIKeyType) {
  try {
    yield call(patchAPIKey, action.tenantId, action.apiAccess, action.apiKey)
    yield put(loadAPIAccessList(action.tenantId))
  } catch (error) {
    yield put(addError(CREATE_API_ACCESS, error))
  }
}

export function* deleteApiKeys(action: DeleteAPIKeysType) {
  try {
    yield put(deleteAPIKeyList({ apiKeys: action.apiKeyList }))
    yield call(deleteAPIKeys, action.tenantId, action.apiKeyList)
  } catch (error) {
    yield put(
      addAPIKeyList({
        apiKeys: action.apiKeyList
      })
    )
    yield put(addError(CREATE_API_ACCESS, error))
  }
}
