import defaultsDeep from 'lodash/defaultsDeep'
import codegen from 'babel-plugin-codegen/macro'

const envConfigStr = codegen`
  if (process.env.REACT_APP__DHI_TARGET_ENV) {
    const fs = require('fs')
    const configFile = 'app.config-' + process.env.REACT_APP__DHI_TARGET_ENV + '.json'
    const config = fs.readFileSync(__dirname + '/../../public/config/' + configFile, 'utf8')
    module.exports = JSON.stringify(config)
  } else {
    module.exports = 'false'
  }
`

const getConfigUrl = () => {
  switch (process.env.REACT_APP__DHI_TARGET_ENV) {
    case 'dev':
      return '/config/app.config-dev.json'
    case 'dev0':
      return '/config/app.config-dev0.json'
    case 'localhost':
      return '/config/app.config-local.json'
    default:
      return '/config/appsettings.json'
  }
}

async function fetchConfig() {
  const configPath = getConfigUrl()
  const resp = await fetch(configPath)
  const config = await resp.json()
  return config
}

export default fetchConfig
