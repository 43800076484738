import React from 'react'
import objectHash from 'object-hash'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import { ICreateTenantFormValues } from '../../apis/admin'
import SelectField from '../../components/formik/SelectField'

const submitButtonStyle = css`
  float: right;
`
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  billingReference: Yup.string().required('Required'),
  billingReferenceType: Yup.string().required('Required'),
  billingReferenceTag: Yup.string()
})

interface IProps {
  handleCreateTenant: (values: ICreateTenantFormValues) => void
}

const CreateTenantForm = (props: IProps) => {
  const intl = useIntl()
  const { handleCreateTenant } = props

  const REFERENCE_TYPES = [
    {
      id: 'Maconomy',
      primaryField: intl.formatMessage({
        id: 'common.yes'
      }),
      secondaryField: ''
    },
    {
      id: 'Dynamics',
      primaryField: intl.formatMessage({
        id: 'common.no'
      }),
      secondaryField: intl.formatMessage({
        id: 'createTenantForm.dynamics.secondary'
      })
    }
  ]

  const defaultInitialValues: ICreateTenantFormValues = {
    name: '',
    billingReference: '',
    billingReferenceType: 'Maconomy',
    billingReferenceTag: ''
  }

  return (
    <Formik
      key={objectHash(defaultInitialValues)}
      initialValues={defaultInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreateTenant}
    >
      {formik => {
        const {
          handleSubmit,
          handleChange,
          setFieldTouched,
          values,
          setFieldValue
        } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        const handleBillingReferenceTypeChange = e => {
          setFieldValue('billingReferenceType', e.target.value)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="billingReference"
                  label={intl.formatMessage({
                    id: 'createTenantForm.billingReference'
                  })}
                  fullWidth
                  required
                  onChange={change.bind(null, 'billingReference')}
                />
              </Grid>
              <SelectField
                name="billingReferenceType"
                label={intl.formatMessage({
                  id: 'createTenantForm.billingReferenceType'
                })}
                valueField="id"
                primaryField="primaryField"
                secondaryField="secondaryField"
                items={REFERENCE_TYPES}
                required
                fullWidth
                value={values.billingReferenceType}
                onChange={handleBillingReferenceTypeChange}
              />
              <Grid item xs={12}>
                <TextField
                  name="billingReferenceTag "
                  label={intl.formatMessage({
                    id: 'createTenantForm.billingReferenceTag'
                  })}
                  fullWidth
                  required
                  onChange={change.bind(null, 'billingReferenceTag')}
                />
              </Grid>
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  type="button"
                >
                  {intl.formatMessage({
                    id: 'createTenantForm.createTenantButton'
                  })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateTenantForm
