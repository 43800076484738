import filter from 'lodash/fp/filter'
import lowerCase from 'lodash/lowerCase'

import { metadata, administration } from './support'

const BASE_IAM_URL = 'iam'

const filterCustomerUsers = searchText =>
  filter(
    ({ name, email }) =>
      lowerCase(name).includes(lowerCase(searchText)) ||
      lowerCase(email).includes(lowerCase(searchText))
  )

export const getCustomerUsers = async (projectId, searchText) => {
  try {
    const { data } = await administration.getJson(
      `${BASE_IAM_URL}/project/${projectId}/principal/list`,
      3
    )
    return filterCustomerUsers(searchText)(data)
  } catch (_error) {
    return []
  }
}
