import any from 'lodash/fp/any'
import map from 'lodash/map'
import get from 'lodash/get'

export const CATEGORIES = {
  ALL: 'all',
  MY: 'my',
  SHARED: 'shared',
  CONFIDENTIAL: 'confidential'
}

export const ACCESS_LEVELS = {
  SHARED: 'Shared',
  PRIVATE: 'Private',
  CONFIDENTIAL: 'Confidential'
}

export const ROLE_TYPES = {
  OWNER: 'Owner',
  CONTRIBUTOR: 'Contributor',
  READER: 'Reader'
}

export const ROLE_TYPES_EXT = {
  OWNER: 'Owner',
  CONTRIBUTOR: 'Contributor',
  READER: 'Reader',
  NONE: 'None'
}

export const isUserOwner = user => project =>
  any(({ userId, role }) => userId === user.id && role === ROLE_TYPES.OWNER)(
    project.members
  )

export const isUserMember = user => project =>
  any(({ userId }) => userId === user.id)(project.members)

export const enhanceProjectMembers = (members, customerUsers) => {
  const results = map(members, member => ({
    ...member,
    ...get(customerUsers, member.userId)
  }))
  return results
}
