import AppPropTypes from '../../shared/appPropTypes'
import CssBaseline from '@material-ui/core/CssBaseline'
import Fade from '@material-ui/core/Fade'
import MikeLogin from '../../components/mike-login/MikeLogin'
import IAMNav from '../../components/IAMNav'
import MikeSupportMenuContainer from '@mike/mike-shared-frontend/mike-support'
import { ReactComponent as DataAdmin } from '@mike/mike-shared-frontend/media/icons/DataAdmin'
import MikeTopbar from '@mike/mike-shared-frontend/mike-topbar/mike-topbar'
import ProgressSpinner from '@mike/mike-shared-frontend/mike-topbar-progress-spinner'
import MikeNavWarnBanner from '@mike/mike-shared-frontend/mike-nav-warn-banner/MikeNavWarnBanner'
import MikeSlidingPanel from '@mike/mike-shared-frontend/mike-sliding-panel'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { ReactComponent as Refresh } from '@mike/mike-shared-frontend/media/icons/Refresh'
import Tooltip from '@material-ui/core/Tooltip'
import CreateTenantForm from '../../components/CreateTenantForm'
import CreateTenantButton from '../buttons/CreateTenantButton'

import Grid from '@material-ui/core/Grid'
import ChangeSiteButton from '../buttons/ChangeSiteButton'
import MikeCloudApps from '../MikeCloudApps'
import { loadAdminCredits } from '../../actions/admin'
import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: {
    minHeight: theme.spacing(5),
    backgroundColor: theme.palette.lightGrey.main
  },
  warningTempBanner: {
    display: 'flex',
    height: '45px',
    width: '100vw',
    backgroundColor: '#f57171',
    marginTop: '-4px',
    marginBottom: '-10px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.lightGrey.main
  },
  header: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    borderBottom: '2px solid ' + theme.palette.mediumGrey.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  tenantContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '33vw'
  },
  tenantSelect: {
    width: '15vw'
  },
  tenantButton: {
    backgroundColor: theme.palette.lightGrey.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  placeHolder: {
    backgroundColor: theme.palette.lightGrey.main,
    minHeight: theme.spacing(1.5)
  },
  placeSelectTop: {
    backgroundColor: theme.palette.lightGrey.main,
    minHeight: theme.spacing(0.5)
  },
  placeSelectBottom: {
    backgroundColor: theme.palette.lightGrey.main,
    minHeight: theme.spacing(1)
  },
  headerRightPlaceHolder: {
    minWidth: '33vw'
  },
  iamNav: {
    width: '33vw',
    backgroundColor: theme.palette.lightGrey.main
  },
  actions: {
    display: 'flex',
    '& div': {
      '& svg': {
        fill: 'grey'
      }
    }
  },
  creditsItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  creditsText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main
  },
  rightActionButtons: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: '32px'
  },
  switchTenantButton: {
    marginRight: 20
  }
})

const APPLICATION_TYPE = 'Application'

const APP_TO_FILTER = [
  'Company Subscription (giving access to Cloud Admin)',
  'Flood Screener',
  'Global Seas'
]

const AppLayout = ({
  children,
  classes,
  support,
  tutorial,
  progressItems,
  loadingCredits,
  credits,
  tenants,
  openCreateTenant,
  onCreateTenantCloseClick,
  onCreateTenant,
  onOpenCreateTenant,
  isAdmin,
  isBackOffice,
  loadingApps,
  features,
  currentProject,
  user
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [warningVisible, setWarningVisible] = useState(false)

  const apps = features.filter(feat => {
    return feat.type === APPLICATION_TYPE && !APP_TO_FILTER.includes(feat.name)
  })

  const canCreateTenants = isBackOffice

  const mailConfig = {
    to: support.toEmail,
    subject: support.subject,
    body: support.body
  }
  const documentationConfig = { href: tutorial.link, target: '_blank' }
  const documentationLibraryConfig = {
    href: tutorial.linkLibrary,
    target: '_blank',
    label: 'Documentation Library'
  }
  const platformDocumentationConfig = {
    href: tutorial.platformDocumentation,
    target: '_blank',
    label: 'Platform Documentation'
  }

  const portalSupportConfig = {
    href: support.portalLink,
    target: '_blank',
    label: 'Support portal'
  }

  const refreshCredits = React.useCallback(() => {
    dispatch(loadAdminCredits())
  }, [dispatch])

  const logoutCallback = React.useCallback(() => {
    const _paq = (window._paq = window._paq || [])
    _paq.push(['resetUserId'])
    _paq.push(['appendToTrackingUrl', 'new_visit=1'])
    _paq.push(['trackPageView'])
    _paq.push(['appendToTrackingUrl', ''])
  }, [])

  const supportMenu = (
    <MikeSupportMenuContainer
      mailConfig={mailConfig}
      documentationConfig={[
        documentationConfig,
        documentationLibraryConfig,
        platformDocumentationConfig,
        portalSupportConfig
      ]}
      tooltipPosition={{ top: -60, left: -20 }}
    >
      <Tooltip title={'Click me to update remaining credits'}>
        <div className={classes.creditsItem} onClick={refreshCredits}>
          <Refresh />
          <Typography className={classes.creditsText}>
            {'Remaining Credits:'}
          </Typography>
          {loadingCredits ? (
            <CircularProgress size={20} />
          ) : (
            <Typography className={classes.creditsText}>{credits}</Typography>
          )}
        </div>
      </Tooltip>
    </MikeSupportMenuContainer>
  )

  const loginProps = () => ({
    logInLabel: 'Log in',
    logOutLabel: 'Log out',
    logoutClickCallback: logoutCallback
  })

  const appIcon = <DataAdmin />
  const loginButton = <MikeLogin {...loginProps()} />
  const appTitle = 'MIKE Cloud Admin'
  const actions = (
    <div className={classes.actions}>
      {progressItems && <ProgressSpinner progressItems={progressItems} />}
    </div>
  )

  const props = () => ({
    appIcon: appIcon,
    appTitle: appTitle,
    loginButton: loginButton,
    supportButton: supportMenu,
    actionsArea: actions
  })

  const gridStyles = {
    paddingBottom: 20,
    paddingTop: 20
  }

  const pageHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '40px'
  }

  useEffect(() => {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    const date = today.getDate()
    const url = window.location.origin
    console.log(url)
    if (
      year <= 2023 &&
      month <= 11 &&
      date < 26 &&
      url.includes('mike-cloud.com')
    )
      setWarningVisible(true)
    else setWarningVisible(false)
  }, [])

  return (
    <>
      <CssBaseline />

      <Fade in>
        <div className={classes.root}>
          <MikeTopbar {...props()} />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <MikeNavWarnBanner />
            {warningVisible && (
              <div className={classes.warningTempBanner}>
                <Typography variant="subtitle1">
                  {
                    <>
                      <b>{'Downtime of MIKE Cloud Platform:'}</b>{' '}
                      {
                        'Please be aware that cloud services will be temporarily unavailable on'
                      }
                      <b>{' Saturday 25th November 2023'}</b>{' '}
                      {'due to planned database migration.'}
                    </>
                  }
                </Typography>
              </div>
            )}

            <Grid container style={gridStyles}>
              <Grid item xs style={pageHeaderStyle}>
                <Typography variant="h6">{user?.tenantName}</Typography>
              </Grid>
              <MikeSlidingPanel
                position="right"
                isOpen={openCreateTenant}
                onClose={onCreateTenantCloseClick}
                titleArea={intl.formatMessage({
                  id: 'createTenantForm.createTenantButton'
                })}
                contentArea={
                  <CreateTenantForm handleCreateTenant={onCreateTenant} />
                }
                actionsArea={null}
                noGrayOverlay={false}
              />
              {user && user.tenantId ? (
                <Grid
                  item
                  xs
                  direction="column"
                  justifyContent="center"
                  container
                >
                  <IAMNav showSiteAccess={isAdmin} />
                </Grid>
              ) : null}

              <Grid item xs direction="row" justifyContent="flex-end" container>
                <div className={classes.rightActionButtons}>
                  {tenants?.length > 1 && (
                    <ChangeSiteButton className={classes.switchTenantButton} />
                  )}
                  {canCreateTenants && (
                    <CreateTenantButton onClick={onOpenCreateTenant} />
                  )}
                </div>
              </Grid>
            </Grid>
            <MikeCloudApps
              loading={loadingApps}
              applications={apps}
              projectId={currentProject?.details?.data?.id}
              tenantId={user?.tenantId}
            />
            {children}
          </main>
        </div>
      </Fade>
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  loginConfig: AppPropTypes.loginConfig.isRequired,
  support: AppPropTypes.support.isRequired,
  tutorial: AppPropTypes.tutorial.isRequired,
  user: AppPropTypes.user,
  appNotifications: PropTypes.array.isRequired,
  progressItems: PropTypes.array,
  loadingCredits: PropTypes.bool.isRequired,
  credits: PropTypes.number.isRequired,
  loadingTenants: PropTypes.bool.isRequired,
  tenants: PropTypes.array,
  tenantId: PropTypes.string,
  openCreateTenant: PropTypes.bool,
  onCreateTenantCloseClick: PropTypes.func.isRequired,
  onCreateTenant: PropTypes.func.isRequired,
  onOpenCreateTenant: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isBackOffice: PropTypes.bool,
  loadingApps: PropTypes.bool,
  apps: PropTypes.object,
  project: PropTypes.object,
  features: PropTypes.array,
  currentProject: PropTypes.object
}

const enhance = compose(withStyles(styles))

export default enhance(AppLayout)
