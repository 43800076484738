import { combineReducers } from 'redux'

import auth from './auth'
import converts from './converts'
import customerUsers from './customerUsers'
import datasetReaders from './datasetReaders'
import datasetWriters from './datasetWriters'
import errors from './errors'
import exports from './exports'
import imports from './imports'
import intl from './intl'
import project from './project'
import projects from './projects'
import recycleBin from './recycleBin'
import ui from './ui'
import uploads from './uploads'
import applications from './applications'
import progressItems from './progressItems'
import coordinateSystems from './coordinateSystems'
import admin from './admin'
import tenant from './tenant'
import users from './users'
import apiAccess from './apiAccess'
import apiKeys from './apiKeys'
import groups from './groups'
import features from './features'
import siteCpuUsage from './siteCpuUsage'
import siteStorageUsage from './siteStorageUsage'
import projectNames from './projectNames'
import rootProjectList from './rootProjectList'

import { TypedUseSelectorHook, useSelector } from 'react-redux'

const rootReducer = combineReducers({
  auth,
  admin,
  converts,
  customerUsers,
  applications,
  coordinateSystems,
  datasetReaders,
  datasetWriters,
  errors,
  exports,
  imports,
  intl,
  project,
  projects,
  recycleBin,
  ui,
  uploads,
  progressItems,
  tenant,
  users,
  apiAccess,
  apiKeys,
  groups,
  features,
  siteCpuUsage,
  siteStorageUsage,
  projectNames,
  rootProjectList
})

export default rootReducer
export type typeRootReducer = ReturnType<typeof rootReducer>

export const useTypedSelector: TypedUseSelectorHook<typeRootReducer> = useSelector
