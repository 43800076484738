import React, { Suspense, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose, withState, withHandlers, defaultProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'
import MoreVert from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors'
import { getSortedPageRows } from './support'
import TableBodyDragAndDropArea from './TableBodyDragAndDropArea'

const styles = theme => {
  return {
    root: {
      width: '100%',
      position: 'relative'
    },
    table: {
      width: '100%'
    },
    stickyHeader: props => ({
      position: 'sticky',
      top: props.topOffset,
      zIndex: 1,
      whiteSpace: 'nowrap'
    }),
    stickyActionHeader: props => ({
      position: 'sticky',
      top: props.topOffset,
      width: 40,
      zIndex: 1
    }),
    tableHeader: {
      zIndex: 1
    },
    dragHeader: {
      borderBottom: 'none'
    },
    dragArea: {
      border: '3px solid ' + theme.palette.secondary.main
    },
    dragOverColor: {
      backgroundColor: theme.palette.mediumGrey.main,
      opacity: 0.8
    },
    actionButton: {
      width: 40,
      paddingRight: theme.spacing(4)
    },
    firstColumn: {
      paddingLeft: theme.spacing(4)
    }
  }
}

const DataTable = props => {
  const {
    actions,
    classes,
    loading,
    columns,
    idField,
    rowsPerPage,
    rowsPerPageOptions,
    hasSelection,
    selectedRows = [],
    data = [],
    page,
    totalCount,
    notSortableColumns,
    // eslint-disable-next-line
    topOffset,
    // eslint-disable-next-line
    onChangePage,
    // eslint-disable-next-line
    onChangeRowsPerPage,
    // eslint-disable-next-line
    onColumnClick,
    // eslint-disable-next-line
    onHandleRequestSort,
    // eslint-disable-next-line
    onSelectionChange,
    hasMorePages,
    isSelected,
    processDroppedFiles,
    actionsPopupStateChange,
    _order,
    _orderBy,
    _handleRequestSort,
    _isSelected,
    _handleActionClick,
    _handleClick,
    _handleColumnClick,
    _handleChangePage,
    _handleChangeRowsPerPage,
    // eslint-disable-next-line react/prop-types
    _rowsPerPage
  } = props

  // If totalCount is available or hasMorePages is true, pagination is done server side
  const pageRows = totalCount || hasMorePages ? data : getSortedPageRows(props)
  const selectedRowCount = useMemo(() => {
    if (isSelected) {
      return pageRows.reduce((count, row) => {
        return count + (isSelected(row) ? 1 : 0)
      }, 0)
    }
    return selectedRows.length
  }, [isSelected, selectedRows.length, pageRows])
  const allPageRowsSelected =
    totalCount > 0 && totalCount - selectedRowCount === 0
  const partiallyPageRowsSelected = selectedRowCount > 0 && !allPageRowsSelected

  const manyRowsSelected = !isEmpty(selectedRows) && selectedRows.length > 1
  const dataCount = totalCount > 0 ? totalCount : data ? data.length : 0

  const dragEnterCounter = useRef(0)

  const [dragOver, setDragOver] = React.useState(false)
  const handleDrop = React.useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      if (processDroppedFiles) {
        processDroppedFiles(event.dataTransfer.files)
        dragEnterCounter.current = 0
        setDragOver(false)
      }
    },
    [processDroppedFiles]
  )

  const handleDragOver = React.useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  const handleDragLeave = React.useCallback(() => {
    if (processDroppedFiles) {
      dragEnterCounter.current = dragEnterCounter.current - 1
      if (dragEnterCounter === 0) {
        setDragOver(false)
      }
    }
  }, [dragEnterCounter, processDroppedFiles])

  const handleDragEnter = React.useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      if (processDroppedFiles) {
        dragEnterCounter.current = dragEnterCounter.current + 1
        setDragOver(true)
      }
    },
    [dragEnterCounter, processDroppedFiles]
  )

  const emptyTableWithDragFunctionaly =
    processDroppedFiles && (dataCount === 0 || dragOver)

  const tableHeadCheckboxChange = event => {
    const newSelected =
      event.target.checked && !(selectedRowCount === rowsPerPage)
        ? getSortedPageRows(props)
        : []
    onSelectionChange(newSelected)
  }

  return (
    <Paper
      className={classes.root}
      elevation={emptyTableWithDragFunctionaly ? 0 : 1}
    >
      <Table
        className={classes.table}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <EnhancedTableHead
          actions={actions}
          allPageRowsSelected={allPageRowsSelected}
          classes={classes}
          columns={columns}
          noBottomBorder={emptyTableWithDragFunctionaly}
          notSortableColumns={notSortableColumns}
          onRequestSort={_handleRequestSort}
          onTableHeadCheckboxChange={tableHeadCheckboxChange}
          order={_order}
          orderBy={_orderBy}
          partiallyPageRowsSelected={partiallyPageRowsSelected}
          hasSelection={hasSelection}
        />

        {loading ? (
          <TableBodySkeleton
            skeletonHead={[
              ...Array(columns.length).keys(),
              actions ? 'actions' : null
            ]}
            skeletonRows={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          ></TableBodySkeleton>
        ) : processDroppedFiles && dataCount === 0 ? (
          <TableBodyDragAndDropArea
            dragOver={dragOver}
            columns={[
              'checkbox',
              ...Array(columns.length).keys(),
              actions ? 'actions' : null
            ]}
            height={'336px'}
          />
        ) : (
          <TableBody className={dragOver ? classes.dragArea : ''}>
            {pageRows.map(item => {
              const rowKey = getIdFieldValue(item, idField)
              const isItemSelected = isSelected
                ? isSelected(item)
                : _isSelected(item)

              return (
                <TableRow
                  hover
                  role={hasSelection !== false ? 'checkbox' : null}
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={rowKey}
                  selected={isItemSelected}
                >
                  {hasSelection !== false && (
                    <TableCell
                      className={classNames(
                        classes.firstColumn,
                        dragOver ? classes.dragOverColor : ''
                      )}
                      padding="checkbox"
                      onClick={event => _handleClick(event, item)}
                    >
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                  )}

                  {columns.map((col, index) => {
                    const value = getValue(col, item, data)
                    const cellKey = getCellKey(col, item)
                    const render = getRender(col)

                    return (
                      <TableCell
                        className={classNames(
                          col.className ? col.className(item) : '',
                          dragOver ? classes.dragOverColor : '',
                          hasSelection === false &&
                            index === 0 &&
                            classes.firstColumn
                        )}
                        key={cellKey}
                        padding={getPadding(col)}
                        align={col.numeric ? 'right' : 'left'}
                        onClick={event => _handleColumnClick(event, item)}
                      >
                        {render(value, item, data)}
                      </TableCell>
                    )
                  })}
                  {actions ? (
                    <TableCell
                      align="left"
                      className={classNames(
                        classes.actionButton,
                        dragOver ? classes.dragOverColor : ''
                      )}
                      onClick={event => _handleActionClick(event)}
                    >
                      <IconButton
                        className={classes.iconButton}
                        disabled={manyRowsSelected}
                      >
                        <Actions
                          actions={actions}
                          row={item}
                          actionsPopupStateChange={actionsPopupStateChange}
                        />
                      </IconButton>
                    </TableCell>
                  ) : null}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
      {(dataCount > 10 || totalCount === -1) && (
        <TablePagination
          component="div"
          count={totalCount === -1 ? -1 : dataCount}
          rowsPerPage={_rowsPerPage ? _rowsPerPage : 25}
          page={page}
          rowsPerPageOptions={
            rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50]
          }
          onPageChange={_handleChangePage}
          onRowsPerPageChange={_handleChangeRowsPerPage}
          nextIconButtonProps={
            hasMorePages === false
              ? {
                  className: 'Mui-disabled',
                  disabled: true
                }
              : {}
          }
        />
      )}
    </Paper>
  )
}

export const columnPropType = PropTypes.exact({
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  label: PropTypes.string.isRequired,
  numeric: PropTypes.bool,
  disablePadding: PropTypes.bool,
  render: PropTypes.func,
  comparator: PropTypes.func,
  className: PropTypes.any
})

DataTable.propTypes = {
  actions: PropTypes.array,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(columnPropType).isRequired,
  idField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  isSelected: PropTypes.func,
  data: PropTypes.array,
  hasSelection: PropTypes.bool,
  selectedRows: PropTypes.array,
  totalCount: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
  onColumnClick: PropTypes.func,
  onHandleRequestSort: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  page: PropTypes.number,
  notSortableColumns: PropTypes.array,
  hasMorePages: PropTypes.bool,
  processDroppedFiles: PropTypes.func,
  actionsPopupStateChange: PropTypes.func,
  _order: PropTypes.arrayOf(PropTypes.string),
  _orderBy: PropTypes.array,
  _handleRequestSort: PropTypes.func.isRequired,
  _isSelected: PropTypes.func.isRequired,
  _handleActionClick: PropTypes.func.isRequired,
  _handleColumnClick: PropTypes.func.isRequired,
  _handleClick: PropTypes.func.isRequired,
  _handleChangePage: PropTypes.func.isRequired,
  _handleChangeRowsPerPage: PropTypes.func.isRequired
}

const getValue = (col, item, data) =>
  isFunction(col.value)
    ? col.value(item, data)
    : get(getCellKey(col, item))(item)

const getCellKey = (col, item) =>
  isFunction(col.field) ? col.field(item) : col.field

const getRender = col => col.render || (value => value)

const EnhancedTableHead = ({
  actions,
  allPageRowsSelected,
  classes,
  columns,
  noBottomBorder,
  notSortableColumns,
  onRequestSort,
  onTableHeadCheckboxChange,
  order,
  orderBy,
  partiallyPageRowsSelected,
  hasSelection
}) => (
  <TableHead component="thead" className={classes.tableHeader}>
    <TableRow className={classes.tableHeader}>
      {hasSelection !== false && (
        <TableCell
          component="th"
          padding="checkbox"
          className={classNames(
            classes.firstColumn,
            classes.stickyHeader,
            noBottomBorder && classes.dragHeader
          )}
        >
          <Checkbox
            indeterminate={partiallyPageRowsSelected}
            checked={allPageRowsSelected}
            onChange={onTableHeadCheckboxChange}
          />
        </TableCell>
      )}

      {columns.map((col, index) => {
        return (
          <TableCell
            className={classNames(
              classes.stickyHeader,
              noBottomBorder && classes.dragHeader,
              hasSelection === false && index === 0 && classes.firstColumn
            )}
            key={col.field}
            align={col.numeric ? 'right' : 'left'}
            padding={getPadding(col)}
            component="th"
            sortDirection={
              orderBy.includes(col.comparator ? col.comparator : col.field)
                ? order[
                    col.comparator
                      ? orderBy.indexOf(col.comparator)
                      : orderBy.indexOf(col.field)
                  ]
                : false
            }
          >
            {!notSortableColumns.includes(col.field) ? (
              <TableSortLabel
                active={
                  col.comparator
                    ? orderBy.includes(col.comparator)
                    : orderBy.includes(col.field)
                }
                direction={order[0]}
                onClick={event =>
                  onRequestSort(
                    event,
                    col.comparator ? col.comparator : col.field
                  )
                }
              >
                {col.label}
              </TableSortLabel>
            ) : (
              col.label
            )}
          </TableCell>
        )
      })}
      {actions ? (
        <TableCell
          className={classNames(
            classes.actionButton,
            classes.stickyActionHeader,
            noBottomBorder && classes.dragHeader
          )}
          align="center"
        />
      ) : null}
    </TableRow>
  </TableHead>
)
EnhancedTableHead.propTypes = {
  actions: PropTypes.array,
  allPageRowsSelected: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(columnPropType).isRequired,
  noBottomBorder: PropTypes.bool,
  notSortableColumns: PropTypes.array,
  onRequestSort: PropTypes.func.isRequired,
  onTableHeadCheckboxChange: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  orderBy: PropTypes.array,
  partiallyPageRowsSelected: PropTypes.bool.isRequired,
  hasSelection: PropTypes.bool
}

const TableBodySkeleton = ({ skeletonRows, skeletonHead }) => (
  <TableBody>
    {skeletonRows.map(ind => {
      return (
        <TableRow key={ind}>
          {skeletonHead.map((_head, index) => {
            return (
              <TableCell key={index} align="left">
                <Skeleton height={38} />
              </TableCell>
            )
          })}
          <TableCell align="left">
            <Skeleton height={38} />
          </TableCell>
        </TableRow>
      )
    })}
  </TableBody>
)
TableBodySkeleton.propTypes = {
  skeletonRows: PropTypes.array.isRequired,
  skeletonHead: PropTypes.array.isRequired
}

const lazyLoadIconByName = name => {
  return React.lazy(() =>
    import(`@mike/mike-shared-frontend/media/icons/${name.name}`).then(
      module => {
        return {
          default: module.ReactComponent
        }
      }
    )
  )
}

const ActionIcon = name => {
  const Icon = lazyLoadIconByName(name)
  return (
    <>
      <Suspense fallback="">
        <Icon />
      </Suspense>
    </>
  )
}

const Actions = ({ row, actions, actionsPopupStateChange }) => (
  <PopupState variant="popover" popupId="action-popup-menu">
    {popupState => {
      actionsPopupStateChange && actionsPopupStateChange(popupState.isOpen, row)
      return (
        <React.Fragment>
          <MoreVert {...bindTrigger(popupState)} />
          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            MenuListProps={{
              style: {
                padding: 0,
                backgroundColor: MIKE_COLORS.XLIGHTGREY
              }
            }}
          >
            {actions
              .map((a, index) => {
                const renderedValue = a.render
                  ? a.render(row, a.parentCapabilities)
                  : a.name
                if (renderedValue) {
                  return (
                    <MenuItem
                      key={index}
                      onClick={e => {
                        e.preventDefault()
                        popupState.close()
                        a.callBack(row, a.name)
                      }}
                      disableGutters={a.disableGutters}
                    >
                      {a.icon ? <ActionIcon name={a.icon} /> : ''}
                      {renderedValue}
                    </MenuItem>
                  )
                }
                return null
              })
              .filter(val => val !== null)}
          </Menu>
        </React.Fragment>
      )
    }}
  </PopupState>
)
Actions.propTypes = {
  row: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  actionsPopupStateChange: PropTypes.func
}

const getPadding = col => (col.disablePadding ? 'none' : 'normal')

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const getIdFieldValue = (item, idField) => {
  if (typeof idField === 'function') {
    return idField(item)
  }
  return item[idField]
}

const enhance = compose(
  withRouter,

  withStyles(styles),

  defaultProps({
    loading: false,
    rowsPerPageOptions: [10, 25, 50],
    data: [],
    notSortableColumns: []
  }),

  withState('_order', '_setOrder', props =>
    props._order ? props._order : ['asc']
  ),
  withState('_orderBy', '_setOrderBy', props =>
    props._orderBy
      ? props._orderBy
      : [
          props.columns[0].comparator
            ? props.columns[0].comparator
            : props.columns[0].field
        ]
  ),
  withState('_rowsPerPage', '_setRowsPerPage', props =>
    props._rowsPerPage ? props._rowsPerPage : 25
  ),
  withState('_page', '_setPage', props => (props.page ? props.page : 0)),

  withHandlers({
    _isSelected: ({ selectedRows, idField }) => item => {
      const itemId = getIdFieldValue(item, idField)

      return Boolean(
        find(
          selectedRows,
          rowItem => getIdFieldValue(rowItem, idField) === itemId
        )
      )
    },

    _handleRequestSort: props => (_event, property) => {
      const {
        _orderBy,
        _order,
        _setOrder,
        _setOrderBy,
        onHandleRequestSort
      } = props

      const order =
        _orderBy.includes(property) &&
        _order[_orderBy.indexOf(property)] === 'desc'
          ? 'asc'
          : 'desc'

      _setOrder([order])

      _setOrderBy([property])

      onHandleRequestSort && onHandleRequestSort(property, order)
    },

    _handleActionClick: () => _event => {
      _event.stopPropagation()
    },

    _handleColumnClick: props => (_event, item) => {
      const { onColumnClick } = props
      _event.stopPropagation()
      onColumnClick && onColumnClick(item)
    },

    _handleClick: props => (_event, item) => {
      const { onSelectionChange, selectedRows, idField } = props

      const itemId = getIdFieldValue(item, idField)

      const selectedIndex = findIndex(
        selectedRows,
        rowItem => getIdFieldValue(rowItem, idField) === itemId
      )

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, item)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1))
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        )
      }

      onSelectionChange(newSelected)
    },

    _handleChangePage: props => (_event, page) => {
      const { onChangePage } = props
      onChangePage
        ? onChangePage(
            page,
            capitalize(props._orderBy),
            capitalize(props._order[0])
          )
        : props._setPage(page)
    },

    _handleChangeRowsPerPage: props => event => {
      const { onChangeRowsPerPage } = props
      onChangeRowsPerPage &&
        onChangeRowsPerPage(
          event.target.value,
          capitalize(props._orderBy),
          capitalize(props._order[0])
        )

      props._setRowsPerPage(event.target.value)
    }
  })
)

export default enhance(DataTable)
