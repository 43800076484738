import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { useOidc } from '@axa-fr/react-oidc'
import { MikeButton } from '@mike/mike-shared-frontend'
import LandingLayout from './LandingLayout'
import { LOGGINGOUT } from '../mike-login'
import { StringMap } from '@axa-fr/react-oidc/dist/vanilla/oidc'

const localMessages = defineMessages({
  button: {
    id: 'screens.loginScreen.button'
  },
  unsureHint: {
    id: 'screens.loginScreen.unsureHint'
  },
  unsureLink: {
    id: 'screens.loginScreen.unsureLink'
  }
})

const useStyles = makeStyles(theme =>
  createStyles({
    notes: {
      marginTop: 20,
      display: 'inline-block'
    }
  })
)

const LandingPage = () => {
  const classes = useStyles()
  const { login } = useOidc()
  const intl = useIntl()

  const { search, pathname } = useLocation()

  const pathAndSearch = search.length > 1 ? pathname + search : pathname

  useEffect(() => {
    const loggingOut = sessionStorage.getItem(LOGGINGOUT)
    sessionStorage.removeItem(LOGGINGOUT)
    if (loggingOut !== 'true') {
      let extras: StringMap = { prompt: 'none' }
      if (search) {
        const parameters = qs.parse(search.replace('?', '')) || {}
        if (parameters.tenantId) {
          extras = { ...extras, tenantId: parameters.tenantId.toString() }
        }
      }
      login(pathAndSearch, extras)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LandingLayout>
      <>
        <Grid item container justifyContent="center" xs={12}>
          <MikeButton
            color="secondary"
            variant="contained"
            onClick={() => login(pathAndSearch)}
          >
            {intl.formatMessage(localMessages.button)}
          </MikeButton>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" className={classes.notes}>
            <label>{intl.formatMessage(localMessages.unsureHint)}</label>&nbsp;
            <a href="mailto:mike@dhigroup.com&Subject=Access%20to%20DHI%20Cloud%20Bridge">
              {intl.formatMessage(localMessages.unsureLink)}
            </a>
            .
          </Typography>
        </Grid>
      </>
    </LandingLayout>
  )
}

export default connect()(LandingPage)
