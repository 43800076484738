import map from 'lodash/map'
import pick from 'lodash/fp/pick'
import size from 'lodash/fp/size'
import orderBy from 'lodash/orderBy'
import { isDataset, capitalize } from '../helpers/projectContent'

import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  fork
} from 'redux-saga/effects'

import flow from 'lodash/flow'
import sortBy from 'lodash/fp/sortBy'
import mapValues from 'lodash/fp/mapValues'

import { addError } from '../actions/errors'

import {
  LOAD_PROJECTCONTENT,
  DELETE_PROJECT_DATASETS,
  deletingProjectDatasets,
  loadingProjectDetails,
  loadingProjectMembers,
  loadingProjectSubprojects,
  loadingProjectParent,
  loadingProjectInitialTree,
  LOAD_PROJECT_DATASETS,
  LOAD_PROJECT_DETAILS,
  LOAD_PROJECT_MEMBERS,
  LOAD_PROJECT_SUBPROJECTS,
  LOAD_PROJECT_PARENT,
  setProjectDetails,
  setProjectMembers,
  setProjectSubprojects,
  setProjectParent,
  UPDATE_PROJECT_DATASET,
  updatingProjectDataset,
  DELETE_PROJECT_FOLDERS,
  deletingSubProjects,
  UPDATE_PROJECT_DETAILS,
  movingContent,
  MOVE_PROJECT_CONTENT,
  LOAD_PROJECT_ROOT,
  setProjectRoot,
  loadingProjectSubprojectsTree,
  setProjectSubprojectsTree,
  setProjectInitialTree,
  setProjectPath,
  setProjectPagination,
  setProjectRowsPerPage,
  setProjectContent,
  loadProjectContent
} from '../actions/project'

import {
  DELETE_PROJECTS,
  UPDATE_PROJECT,
  updatingProject
} from '../actions/projects'

import {
  delProjectDataset,
  getProject,
  getProjectDatasets,
  getProjectMembers,
  putProjectDataset,
  getProjectSubprojects,
  moveDataset,
  getProjectPath,
  getCapabilities,
  getProjectFolders
} from '../apis/project'

import {
  delProject,
  moveProject,
  putProject,
  patchProject
} from '../apis/projects'

import { putProjectMembers } from '../apis/members'

import { notify, linkNotify } from '../helpers/saga'

import {
  getCustomerUsers,
  getProjectDetails,
  getProjectTree,
  getProjectInitialTree,
  getProjectContentPagination,
  getProjectContentRowsPerPage,
  getProjectContentNamePrefix,
  getProjectContentSortBy,
  getProjectContentSortOrder
} from '../state'

const TAG = 'saga/project'

export default function* watchProject() {
  yield takeEvery(UPDATE_PROJECT_DATASET, updateProjectDataset)
  yield takeEvery(DELETE_PROJECT_DATASETS, deleteProjectDatasets)
  yield takeEvery(DELETE_PROJECT_FOLDERS, deleteSubProjects)
  yield takeEvery(UPDATE_PROJECT_DETAILS, updateProjectDetails)
  yield takeEvery(MOVE_PROJECT_CONTENT, moveContent)
  yield takeLatest(LOAD_PROJECT_DETAILS, loadProjectDetails)
  yield takeLatest(LOAD_PROJECT_DATASETS, loadProjectDatasets)
  yield takeLatest(LOAD_PROJECT_MEMBERS, loadProjectMembers)
  yield takeLatest(LOAD_PROJECT_SUBPROJECTS, loadProjectSubprojects)
  yield takeLatest(LOAD_PROJECT_ROOT, loadProjectRoot)
  yield takeLatest(LOAD_PROJECTCONTENT, loadFoldersAndDatasets)
}

const getSort = sortBy => {
  let sorter = [sortBy]
  switch (sortBy) {
    case 'name':
      sorter = [content => content.name.toLowerCase()]
      break
    case 'description':
      sorter = [
        content => content.description && content.description.toLowerCase()
      ]
      break
    case 'datasetFormat':
      sorter = [
        content => content.datasetFormat && content.datasetFormat.toLowerCase()
      ]
      break
    case 'datasetType':
      sorter = [
        content => content.datasetType && content.datasetType.toLowerCase()
      ]
      break
    default:
      break
  }
  return sorter
}

const removeSortByIfNotSupported = sortBy => {
  const supported = ['Name', 'CreatedAt', 'UpdatedAt', 'AccessLevel']
  return supported.includes(sortBy) ? sortBy : ''
}

const isDatasetBelowOffset = (item, index, offset) => {
  return offset > index && isDataset(item)
}

export function* loadFoldersAndDatasets(action) {
  try {
    yield put(loadingProjectSubprojects())
    let projectContent = []
    let count = 0

    const capabilities = yield call(getCapabilities, action.projectId)

    if (capabilities.canListContent) {
      // To fetch datasets no paginated endpoint is available yet
      const projectDatasets = yield call(getProjectDatasets, action.projectId)
      const datasets = projectDatasets.filter(dataset =>
        dataset.name.startsWith(action.namePrefix)
      )

      // The subprojects endpoint supports server side pagination, sorting
      // and filtering by name prefix
      const { data, totalCount } = yield call(
        getProjectFolders,
        action.projectId,
        action.page === 0 ? 0 : (action.page - 1) * action.rowsPerPage,
        action.rowsPerPage * 2,
        removeSortByIfNotSupported(capitalize(action.sortBy)),
        capitalize(action.sortOrder),
        action.namePrefix
      )

      // No endpoint is available to fetch the paginated project content in one call
      const content = datasets.concat(data)
      const sortedContent = orderBy(
        content,
        getSort(action.sortBy.toLowerCase()),
        [action.sortOrder.toLowerCase()]
      )
      count = totalCount + datasets.length

      // As dataset response is not paginated we need to remove items manually
      const cleanedSortedContent = sortedContent.filter(
        (item, index) =>
          !isDatasetBelowOffset(item, index, action.page * action.rowsPerPage)
      )
      const finalSortedProjectContent = cleanedSortedContent.splice(
        0,
        action.rowsPerPage
      )

      projectContent = finalSortedProjectContent
    }

    yield put(
      setProjectContent(
        projectContent,
        count,
        action.rowsPerPage,
        action.page * action.rowsPerPage,
        action.namePrefix,
        action.sortBy,
        action.sortOrder
      )
    )
  } catch (error) {
    yield put(addError(LOAD_PROJECTCONTENT, error))
  } finally {
    yield put(loadingProjectSubprojects(false))
  }
}

export function* loadProjectMembers(action) {
  try {
    yield put(loadingProjectMembers())

    const rawMembers = yield call(getProjectMembers, action.projectId)
    const users = yield select(getCustomerUsers)
    const members = enhanceMembers(users)(rawMembers)

    yield put(setProjectMembers(members))
  } catch (error) {
    yield put(addError(LOAD_PROJECT_MEMBERS, error))
  } finally {
    yield put(loadingProjectMembers(false))
  }
}

export function* updateProjectDataset(action) {
  const { projectId, data } = action
  const page = yield select(getProjectContentPagination)
  const rowsPerPage = yield select(getProjectContentRowsPerPage)
  const namePrefix = yield select(getProjectContentNamePrefix)
  const sortBy = yield select(getProjectContentSortBy)
  const sortOrder = yield select(getProjectContentSortOrder)

  try {
    yield put(updatingProjectDataset())

    const dataset = yield call(putProjectDataset, projectId, data)

    yield fork(notify, `The dataset "${dataset.name}" was updated!`)
  } catch (error) {
    yield put(addError(UPDATE_PROJECT_DATASET, error))
  } finally {
    yield put(updatingProjectDataset(false))
    yield put(
      loadProjectContent(
        projectId,
        page * rowsPerPage,
        rowsPerPage,
        sortBy,
        sortOrder,
        namePrefix
      )
    )
  }
}

export function* loadProjectDetails(action) {
  const projectId = action.id
  if (projectId) {
    try {
      yield put(loadingProjectDetails())
      const data = yield call(getProjectPath, projectId)
      yield put(setProjectPath(data))
      const capabilities = yield call(getCapabilities, projectId)
      const details = yield call(getProject, projectId)
      yield put(
        setProjectDetails(
          { ...details, capabilities: capabilities },
          capabilities
        )
      )
    } catch (error) {
      yield put(addError(LOAD_PROJECT_DETAILS, error))
    } finally {
      yield put(setProjectPagination(0))
      yield put(setProjectRowsPerPage(25))
      yield put(loadingProjectDetails(false))
    }
  }
}

export function* loadProjectDatasets(action) {
  const page = yield select(getProjectContentPagination)
  const rowsPerPage = yield select(getProjectContentRowsPerPage)
  const namePrefix = yield select(getProjectContentNamePrefix)
  const sortBy = yield select(getProjectContentSortBy)
  const sortOrder = yield select(getProjectContentSortOrder)
  yield put(
    loadProjectContent(
      action.projectId,
      page * rowsPerPage,
      rowsPerPage,
      sortBy,
      sortOrder,
      namePrefix
    )
  )
}

export function* loadProjectSubprojects(action) {
  try {
    action.forTable
      ? yield put(loadingProjectSubprojects())
      : yield put(loadingProjectSubprojectsTree())

    let subProjects = null

    if (action.forTable) {
      yield put(setProjectSubprojects(subProjects))
    } else {
      const tree = yield select(getProjectTree)
      yield put(setProjectSubprojectsTree(subProjects, action.projectId, tree))
    }
  } catch (error) {
    yield put(addError(LOAD_PROJECT_SUBPROJECTS, error))
  } finally {
    yield put(loadingProjectSubprojects(false))
    yield put(loadingProjectSubprojectsTree(false))
  }
}

export function* loadProjectParent() {
  try {
    const project = yield select(getProjectDetails)
    if (project && project.parentProjectId) {
      yield put(loadingProjectParent())
      const parentProject = yield call(getProject, project.parentProjectId)
      yield put(setProjectParent(parentProject))
    } else {
      yield put(setProjectParent(null))
    }
  } catch (error) {
    yield put(addError(LOAD_PROJECT_PARENT, error))
  } finally {
    yield put(loadingProjectParent(false))
  }
}

export function* loadProjectRoot(action) {
  try {
    let { project } = action

    yield put(loadingProjectInitialTree())

    if (!project) return

    if (project.parentProjectId) {
      const parentProject = yield call(getProject, project.parentProjectId)
      const subProjects = yield call(
        getProjectSubprojects,
        project.parentProjectId
      )
      const initialtree = yield select(getProjectInitialTree)
      const newTree = { ...initialtree, [parentProject.id]: subProjects }
      yield put(setProjectInitialTree(newTree))
      project = parentProject
      yield call(loadProjectRoot, { project })
    } else {
      const initialtree = yield select(getProjectInitialTree)
      const newTree = { ...initialtree, project: [project] }
      yield put(setProjectRoot(newTree))
    }
  } catch (error) {
    yield put(addError(LOAD_PROJECT_PARENT, error))
  } finally {
    yield put(loadingProjectInitialTree(false))
  }
}

export function* deleteProjectDatasets(action) {
  const { projectId, datasetIds } = action
  const page = yield select(getProjectContentPagination)
  const rowsPerPage = yield select(getProjectContentRowsPerPage)
  const namePrefix = yield select(getProjectContentNamePrefix)
  const sortBy = yield select(getProjectContentSortBy)
  const sortOrder = yield select(getProjectContentSortOrder)

  try {
    yield put(deletingProjectDatasets())

    yield all(datasetIds.map(datasetId => call(delProjectDataset, datasetId)))

    yield fork(
      notify,
      datasetIds.length > 1
        ? `${datasetIds.length} datasets were deleted!`
        : 'The dataset was deleted!'
    )
  } catch (error) {
    yield put(addError(DELETE_PROJECT_DATASETS, error))
  } finally {
    yield put(deletingProjectDatasets(false))
    yield put(
      loadProjectContent(
        projectId,
        page * rowsPerPage,
        rowsPerPage,
        sortBy,
        sortOrder,
        namePrefix
      )
    )
  }
}

const enhanceMembers = users =>
  flow(
    mapValues(member => {
      const user = users[member.userId]
      if (!user) {
        // eslint-disable-next-line no-console
        console.warn(TAG, `User not found with id "${member.userId}"!`)
      }
      return {
        ...user,
        ...member
      }
    }),
    sortBy('displayName')
  )

export function* deleteSubProjects(action) {
  const { projectParentId, projectIds } = action
  const projectId = projectParentId
  const page = yield select(getProjectContentPagination)
  const rowsPerPage = yield select(getProjectContentRowsPerPage)
  const namePrefix = yield select(getProjectContentNamePrefix)
  const sortBy = yield select(getProjectContentSortBy)
  const sortOrder = yield select(getProjectContentSortOrder)

  try {
    yield put(deletingSubProjects())

    yield all(projectIds.map(id => call(delProject, id)))

    yield fork(
      notify,
      projectIds.length > 1
        ? `${projectIds.length} folders were deleted!`
        : 'The folder was deleted!'
    )
  } catch (error) {
    yield put(addError(DELETE_PROJECTS, error))
  } finally {
    yield put(deletingSubProjects(false))
    yield put(
      loadProjectContent(
        projectId,
        page * rowsPerPage,
        rowsPerPage,
        sortBy,
        sortOrder,
        namePrefix
      )
    )
  }
}

export function* moveContent(action) {
  const { targetProjectId, projectIds, datasetIds, projectId } = action

  const page = yield select(getProjectContentPagination)
  const rowsPerPage = yield select(getProjectContentRowsPerPage)
  const namePrefix = yield select(getProjectContentNamePrefix)
  const sortBy = yield select(getProjectContentSortBy)
  const sortOrder = yield select(getProjectContentSortOrder)
  try {
    yield put(movingContent())

    yield all(projectIds.map(id => call(moveProject, id, targetProjectId)))
    yield all(datasetIds.map(id => call(moveDataset, id, targetProjectId)))

    const project = yield call(getProject, targetProjectId)
    const selectionSize = size(projectIds) + size(datasetIds)
    let message = selectionSize > 1 ? 'Selected items' : 'Selected item'
    message += ' successfully moved to ' + project.name

    yield fork(linkNotify, message, project)
  } catch (error) {
    yield put(addError(MOVE_PROJECT_CONTENT, error))
  } finally {
    yield put(movingContent(false))
    yield put(
      loadProjectContent(
        projectId,
        page * rowsPerPage,
        rowsPerPage,
        sortBy,
        sortOrder,
        namePrefix
      )
    )
  }
}

export function* updateProjectDetails({ data, projectDidUpdate }) {
  const { members, accessLevel, ...projectData } = data

  const updatedMembers = map(members, pick(['userId', 'role']))

  const {
    id,
    name,
    capabilities: { canEditAccessLevel, canGrantAccess, canEdit },
    rowVersion
  } = projectData

  try {
    yield put(updatingProject())
    let versionId = rowVersion
    if (canEdit) {
      const response = yield call(putProject, projectData)
      if (response && response.rowVersion) {
        versionId = response.rowVersion
      }
    }

    if (canEditAccessLevel) {
      const response = yield call(patchProject, id, { accessLevel }, versionId)
      if (response && response.rowVersion) {
        versionId = response.rowVersion
      }
    }

    if (canGrantAccess) {
      yield call(putProjectMembers, id, updatedMembers, versionId)
    }

    yield fork(notify, `Project "${name}" was updated!`)
  } catch (error) {
    if (error && error.httpStatus && error.httpStatus === 409) {
      yield put(
        addError(
          UPDATE_PROJECT,
          'Somebody else was updating the project at the same time, you can refresh your page to see the latest changes.'
        )
      )
    } else {
      yield put(addError(UPDATE_PROJECT, error))
    }
  } finally {
    yield put(updatingProject(false))
    yield call(loadProjectDetails, { id })
    yield call(projectDidUpdate)
  }
}
