// import MikeTooltip from '@mike/mike-shared-frontend/mike-tooltip/MikeTooltip'

import React from 'react'
import messages from '../../shared/messages'
import { ReactComponent as Users } from '@mike/mike-shared-frontend/media/icons/Users'
import { ReactComponent as User } from '@mike/mike-shared-frontend/media/icons/User'

import { Tabs, Tab } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { LinkTab } from '../AppNav'
import { ReactComponent as Apps } from '@mike/mike-shared-frontend/media/icons/Apps'

const useAppNavStyles = makeStyles(theme =>
  createStyles({
    tabsIndicator: {
      height: 3
    },
    hiddenTabRoot: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      minHeight: 48
    },
    root: {
      borderBottom: '1px solid' + theme.palette.grey[300],
      paddingLeft: '40px',
      marginBottom: '20px'
    }
  })
)

const UsersNav = () => {
  const intl = useIntl()
  const classes = useAppNavStyles()

  function usePath() {
    return useLocation().pathname
  }

  let currentPath = usePath()

  let activeTab

  switch (currentPath) {
    case '/site-access/users' || '/site-access':
      activeTab = 1
      break
    case '/site-access/api':
      activeTab = 2
      break
    case '/site-access/applications':
      activeTab = 3
      break
    case '/site-access/groups':
      activeTab = 4
      break
    default:
      activeTab = 0
  }
  return (
    <Tabs
      classes={{ indicator: classes.tabsIndicator, root: classes.root }}
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab classes={{ root: classes.hiddenTabRoot }} />
      <LinkTab
        to={'/site-access/users'}
        label={<span>{intl.formatMessage(messages.tenantUsers)}</span>}
        icon={<Users />}
      />
      <LinkTab
        to={'/site-access/api'}
        label={<span>{intl.formatMessage(messages.tenantApiAccess)}</span>}
        icon={<User />}
      />

      {
        <LinkTab
          to={'/site-access/applications'}
          label={<span>{intl.formatMessage(messages.tenantApplications)}</span>}
          icon={<Apps />}
        />
      }
      {/*TODO(masm): we are hiding the groups functionalities till we dont have API fully supporting roles/groups */}
      {/*        <LinkTab
        to={'/site-access/groups'}
        label={
          // <MikeTooltip title="Tenant groups">
          <span>{intl.formatMessage(messages.tenantGroups)}</span>
          // </MikeTooltip>
        }
        icon={<Users />}
      /> */}
    </Tabs>
  )
}

export default UsersNav
