import React from 'react'

import { Tabs } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { LinkTab } from '../AppNav'
import { ReactComponent as Info } from '@mike/mike-shared-frontend/media/icons/Info'
import { ReactComponent as TableResults } from '@mike/mike-shared-frontend/media/icons/TableResults'

const useAppNavStyles = makeStyles(theme =>
  createStyles({
    tabsIndicator: {
      height: 3
    },
    hiddenTabRoot: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      minHeight: 48
    },
    root: {
      borderBottom: '1px solid' + theme.palette.grey[300],
      paddingLeft: '40px',
      marginBottom: '20px'
    }
  })
)

const SiteInfoNav = () => {
  const intl = useIntl()
  const classes = useAppNavStyles()

  function usePath() {
    return useLocation().pathname
  }

  let currentPath = usePath()

  let activeTab

  switch (currentPath) {
    case '/site-info/details':
      activeTab = 0
      break
    case '/site-info/cpu':
      activeTab = 1
      break
    case '/site-info/storage':
      activeTab = 2
      break
    default:
      activeTab = 0
  }
  return (
    <Tabs
      classes={{ indicator: classes.tabsIndicator, root: classes.root }}
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
    >
      <LinkTab
        to={'/site-info/details'}
        label={
          <span>{intl.formatMessage({ id: 'screens.siteInfo.tab.info' })}</span>
        }
        icon={<Info />}
      />
      <LinkTab
        to={'/site-info/cpu'}
        label={
          <span>{intl.formatMessage({ id: 'screens.siteInfo.tab.cpu' })}</span>
        }
        icon={<TableResults />}
      />
      <LinkTab
        to={'/site-info/storage'}
        label={
          <span>
            {intl.formatMessage({ id: 'screens.siteInfo.tab.storage' })}
          </span>
        }
        icon={<TableResults />}
      />
    </Tabs>
  )
}

export default SiteInfoNav
